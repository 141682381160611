import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from "jquery";
import heading from '../assets/images/heading.png';
import back from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";
import user from '../assets/images/user.png'

export default () => {
    const [doctorList, setDoctorList] = useState([]);
    const [docid, setDocId] = useState(Number);
    const [doctorDetails, setDoctorDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [specialization, setSpecialization] = useState("");
    const [specializationDetails, setSpecializationDetails] = useState([]);
    const [pincode, setPincode] = useState("");
    const [buttonHide, setButtonHide] = useState(true);
    const [msg, setMsg] = useState("");
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/PickList/get_specialiazation`).then((response) => {
            console.log(response.data);
            if (response.data.status == '1') {
                setSpecializationDetails(response.data.response);
            }
        });


    }, [])

    const specializationHandeler = (e) => {
        setSpecialization(e.target.value);
    }

    const pincodeHandeler = (e) => {
        setPincode(e.target.value);

    }
    const viewDoctorHandeler = (e) => {
        setMsg("");
        if (specialization != "" && pincode != "") {
            console.log(pincode);
            setLoading(true);
            axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/Doctors/get_doctor_list?specialization_id=${specialization}&clinic_pincode=${pincode}`
            ).then(
                (response) => {
                    if (response.data.status == '1') {
                        setLoading(false);
                        console.log(response.data);
                        setDoctorList(response.data.response);
                        setButtonHide(false);
                    }
                }

            ).catch((error) => {
                console.log(error.data);
            });
        }
        else {
            setMsg("Please select sepecialization and pincode");
        }

    }
    const onValUpdate = (e) => {
        console.log(e.target.value)
        setDocId(e.target.value);
        setLoading(true);
        axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/Doctors/get_details_by_doctorsid?id=${e.target.value}`
        ).then(
            (response) => {
                if (response.data) {
                    setLoading(false);
                    console.log(response.data);
                    setDoctorDetails(response.data);
                }
            }

        ).catch((error) => {
            console.log(error.data);
        });



    }
    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/");
    }

    return (
        <>

            {/* <div >
                <img src={heading} style={{ height: 80, width: '100%' }} />
            </div> */}

            <div style={{ textAlign: 'left' }}>
                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={backHandler} />
            </div>
            <p style={{ fontSize: 20, marginTop: -10, fontWeight: 'bold', color: '#A696FF' }}> Doctors</p>


            <div style={{ marginTop: 15 }} >
                <p style={{ textAlign: 'left', fontSize: 16, fontWeight: '600', marginLeft: 20 }}>Select Specialization</p>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                    <select
                        style={{ backgroundColor: 'white', width: '89%', height: 35, border: '1px solid black', borderRadius: 10, marginLeft: 20, fontSize: 18 }}
                        name="specialization"
                        value={specialization}
                        onChange={(event) => specializationHandeler(event)}
                    >
                        <option value="">Select</option>
                        {specializationDetails.map((val) => {
                            return (
                                <>
                                    <option value={val.id} >{val.name} </option>
                                </>
                            );
                        })}

                    </select>



                </div>
            </div>
            <div style={{ marginTop: 15 }} >
                <p style={{ textAlign: 'left', fontSize: 16, fontWeight: '600', marginLeft: 20 }}>Enter pincode</p>


                <div style={{ width: '60%', marginLeft: 22, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <input className="input_style"
                        style={{ fontSize: 18 }}
                        placeholder="Enter pincode here"
                        type='tel'
                        name="pincode"
                        value={pincode}
                        onChange={(event) => pincodeHandeler(event)}
                        maxLength={6}
                    >

                    </input>

                </div>

                <button style={{ width: 120, marginLeft: 224, marginTop: -73 }} className="btn btn-auto btn-primary" onClick={viewDoctorHandeler}>
                    View
                </button>
            </div>

            {loading ?

                <>
                    <p style={{ marginTop: 10, fontSize: 16, fontWeight: 'bold', color: 'green' }}>Loading...</p>


                </>

                :
                <div style={{ marginTop: 20 }}>
                    {
                        doctorList.length > 0 ?


                            doctorList.map((result) => {
                                return (
                                    <div style={{ marginTop: 15 }}>
                                        <div className="doctors-card" key={result.doctorsid}>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '25%', }}>

                                                    <img src={user} style={{ height: 60, width: 60, marginTop: 5, borderRadius: 40 }} />

                                                </div>
                                                <div style={{ width: '75%', textAlign: 'left', marginLeft: 10 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <p style={{ fontSize: 17, fontWeight: '600' }}>{result.first_name} {result.last_name}</p>

                                                    </div>
                                                    <p style={{ fontSize: 14, marginTop: -3 }}>{result.degree} ({result.experience})</p>
                                                    <p style={{ fontSize: 14, marginTop: -8 }}>{result.specialization}</p>
                                                    {/* <p style={{ fontSize: 14, marginTop: -8 }}>{result.experience}</p> */}

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                            :
                            buttonHide ? null :

                                <p style={{ marginTop: 60, fontSize: 16, fontWeight: 'bold', color: 'red' }}>No doctor's found</p>


                    }
                    {msg ? <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red', marginTop: 20 }}>{msg}</p> : null}
                </div>
            }






        </>
    )
}