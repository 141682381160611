import React, { useState, useeffect } from 'react';
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";
import heading from '../assets/images/heading.png';
import Axios from 'axios';

export default () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [msg, setMsg] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const [otpValue, setOtpValue] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [yourOTP, setYourOTP] = useState("");
    const [OTPbackendResponse, setOTPBackendResponse] = useState("");
    const [waitingMsg, setWaitingMsg] = useState('');
    const [isDisplayOTP, setIsDisplayOTP] = useState(false);
    const [OTPsectionHide, setOTPSectionHide] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const onNameUpdate = (e) => {
        setName(e.target.value);
    }
    const onGenderUpdate = (e) => {
        setGender(e.target.value);
    }
    const onEmailUpdate = (e) => {
        setEmail(e.target.value);
    }
    const onPhoneUpdate = (e) => {
        setPhone(e.target.value);
    }
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);


        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    const continueHandler = (e) => {
        const newOtp = otp.join("");
        if (OTPbackendResponse == newOtp) {
            setButtonHide(true);
            setWaitingMsg("Please Wait");
            e.preventDefault();
            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append('first_name', name);
            formdata.append('email', email);
            formdata.append('phone_number', phone);
            $.ajax({
                type: "POST",
                url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/Patients/sign_up`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log(data)

                    if (data.status == "1") {
                        setYourOTP("");
                        setWaitingMsg("");
                        console.log("continueHandler:patient register success response:  ", data);
                        setButtonHide(false);
                        localStorage.setItem('phoneNo', phone);
                        navigate("/");
                    }
                    if (data.status == "0") {
                        setYourOTP("");
                        setWaitingMsg("");
                        setErrMsg("Your phone no already registered in this app please login");
                    }


                },
                error: function (err) {
                    console.log(err.responseText);
                    alert("error has occured  ", err);
                }
            });
        }
        else {
            e.preventDefault();
            setMsg("OTP is not matching");


        }
    }
    // for otp 
    const sendOTPHandler = (e) => {
        if (name != "" && phone != "") {
            setSendingOTP(true);
            setButtonHide(true);
            setMsg("")
            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/Misc/sendotp?phone=${phone}`)
                .then(OTPresponse => {
                    setYourOTP(OTPresponse.data);
                    console.log('sendOTPHandler:otp response \n', OTPresponse.data);
                    setSendingOTP(false);
                    setButtonHide(false);
                    if (OTPresponse.data.OTP_SMS_RESPONSE == 'No') {
                        setOTPBackendResponse(OTPresponse.data.OTP);
                        setOTPSectionHide(true);
                        setIsDisplayOTP(true);
                        setOtpValue(true);
                    }
                    else {
                        setOTPBackendResponse(OTPresponse.data.OTP);
                        setOTPSectionHide(true);
                        setOtpValue(true);
                        setIsDisplayOTP(false);
                    }


                }).catch((err) => {
                    console.log('Reset Password: otp send failed', err);
                    setSendingOTP(false);
                    setButtonHide(false);

                })
        } else {
            setButtonHide(false);
            e.preventDefault();
            setMsg("Name and Phone are mandatory");


        }
    }

    const resendOTP = (e) => {

        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/Misc/sendotp?phone=${phone}`)
            .then(OTPresponse => {
                setYourOTP(OTPresponse.data);
                console.log('sendOTPHandler:otp response \n', OTPresponse.data);
                setSendingOTP(false);
                setButtonHide(false);
                if (OTPresponse.data.OTP_SMS_RESPONSE == 'No') {
                    setOTPBackendResponse(OTPresponse.data.OTP);
                    setOTPSectionHide(true);
                    setIsDisplayOTP(true);
                    setOtpValue(true);
                }
                else {
                    setOTPBackendResponse(OTPresponse.data.OTP);
                    setOTPSectionHide(true);
                    setOtpValue(true);
                    setIsDisplayOTP(false);
                }


            }).catch((err) => {
                console.log('otp send failed', err);
                setSendingOTP(false);
                setButtonHide(false);

            })

    }

    return (
        <div style={{ backgroundColor: 'white', height: 850, marginTop: -30 }}>
            <div className="login" style={{ backgroundColor: 'white', height: '70%', paddingTop: 70 }}>
                <img className="image" src={heading} />

                <div>

                    <h3 style={{ textAlign: 'center', marginTop: 10, fontSize: 20, fontWeight: 'bold' }}>SIGN UP</h3>
                </div>
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>Name *</p>
                    <input type="text" name="Name" required placeholder="name "
                        value={name}
                        maxLength={30}
                        onChange={(event) => onNameUpdate(event)}
                    /><br /><br />

                    <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>Email </p>
                    <input type="email" name="email" required placeholder="email "
                        value={email}
                        maxLength={30}
                        onChange={(event) => onEmailUpdate(event)}
                    /><br /><br />
                    <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>Phone *</p>
                    <input type="tel" name="phone" required placeholder="phone "
                        value={phone}
                        maxLength={10}
                        onChange={(event) => onPhoneUpdate(event)}
                    /><br /><br />
                    {
                        otpValue ?
                            <>

                                <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>Enter otp</p>
                                {otp.map((data, index) => {
                                    return (
                                        <input
                                            className="otp-field"
                                            type="tel"
                                            name="otp"
                                            maxLength="1"
                                            key={index}
                                            value={data}
                                            style={{ width: 40, marginLeft: 10, fontWeight: 'bold', fontSize: 20 }}
                                            onChange={e => handleChange(e.target, index)}
                                            onFocus={e => e.target.select()}
                                        />
                                    );
                                })}

                                {
                                    isDisplayOTP ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: '#6080eeed', marginTop: 20 }}>Your OTP IS : {OTPbackendResponse}</p>
                                        : null

                                }
                                <p style={{ fontSize: 16, color: '#6080eeed' }} onClick={resendOTP}>Resend Otp</p>

                            </>
                            : null
                    }
                </div><br />
                {errMsg ? <p style={{ marginTop: -14, marginLeft: '15px', marginRight: 'auto', color: 'red', fontSize: 14, fontWeight: 'bold' }}>{errMsg}</p> : null}
                {
                    msg ? <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red', marginTop: -20, textAlign: 'center' }}>{msg}</p> : null
                }
                {
                    waitingMsg ? <p style={{ fontSize: 16, fontWeight: 'bold', color: 'green', marginTop: -10, textAlign: 'center' }}>{waitingMsg}</p> : null
                }

                {
                    otpValue ?
                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                            <form
                                method="post"
                                onSubmit={(event) => continueHandler(event)}

                            >
                                {
                                    buttonHide == false ?
                                        <button className="loginbutton" type="submit" >Continue</button>
                                        :
                                        null
                                }
                            </form>
                        </div>
                        :
                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                            <form
                                method="post"
                                onClick={sendOTPHandler}

                            >
                                {
                                    buttonHide == false ?
                                        <button className="loginbutton" type="submit" >SEND OTP</button>
                                        :
                                        <p style={{ fontSize: 14, fontWeight: 'bold', color: 'green' }}>
                                            Please wait...
                                        </p>
                                }
                            </form>
                        </div>
                }



                <div style={{ marginTop: 20 }}>
                    <p>Already have an account?&nbsp;<a style={{ textDecoration: 'none', fontSize: 15, fontWeight: 'bold' }} href="/Login">Sign In</a> </p>


                </div>
            </div>
        </div>
    )
}