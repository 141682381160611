import React from 'react'
import heading from '../assets/images/heading.png'
import doctorsicon from '../assets/images/doctorsicon.png';
import medicalrecords from '../assets/images/medicalrecords.png';
import logout from '../assets/images/logout.png';
import editicon from '../assets/images/editicon.png';
import healthprofile from '../assets/images/healthprofile.jpg';
import consulthistory from '../assets/images/consulthistory.jpg';
import body_temp from '../assets/images/body_temp.png';
import heart_rate from '../assets/images/heart_rate.png';
import blood_sugar from '../assets/images/blood_sugar.png';
import blood_pressure from '../assets/images/blood_pressure.png';
import loginImg from '../assets/images/loginImg.jpg'
import signin from '../assets/images/signIn.png'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jQuery from 'jquery';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";

const LandingPage = () => {
    const navigate = useNavigate();
    const [msg, setMsg] = useState("")


    const accessHandeler = () => {

        setMsg("Click on Sign In for accessing items")
        setTimeout(() => {
            setMsg("")
        }, 3000);

    }

    const signInHandeler = () => {
        navigate("/SignUp");
    }

    const logInHandeler = () => {
        navigate("/Login");
    }
    let id = localStorage.getItem("patientid");

    let tableData = [
        {
            Reading: "100",
            create_date: "03.01.2023",

        },
        {
            Reading: "40",
            create_date: "06.04.2023",


        },
        {
            Reading: "90",
            create_date: "09.07.2023",

        }
    ]
    return (
        <div>
            <div >
                <img src={heading} style={{ height: 80, width: '100%' }} />

            </div>
            <div className="patient_details">
                <div className="details" style={{ width: '100%', height: 'auto' }}>
                    <p style={{ fontSize: 20, fontWeight: 'bold', color: '#000480', textAlign: 'left', paddingLeft: 20 }}>Swastha Yog
                    </p>
                    <p style={{ fontSize: 17, fontWeight: 'bold', color: '#000480' }}> Your connection to
                        Digital Health.

                    </p>
                    <p style={{ fontSize: 17, color: '#000480', textAlign: 'left', paddingLeft: 20 }}>
                        Securely store and view all your
                        prescriptions and lab reports and
                        remain conneted with your doctors and
                        other health providers and labs around you.
                    </p>
                    {
                        id ?
                            <a href="/" style={{ marginLeft: 230 }}>Go to Home</a>
                            :

                            <a href="/LogIn" style={{ display: 'flex', flexDirection: 'row', justifyContent: "end", fontSize: 17, fontWeight: 'bold', color: '#438251', paddingRight: 10 }}>Sign In / Log In</a>
                    }

                </div>

            </div>
            <p style={{ fontSize: 17, color: '#000480' }}>
                What we promise as app capabilities are

            </p>
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" style={{ marginTop: 40 }}>
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>

                </ol>
                <div class="carousel-inner">

                    <div class="carousel-item active">
                        <div class="card-wrapper container-sm d-flex  justify-content-around">
                            <div onClick={accessHandeler}>
                                <button className="common-flex-button2" > Health Profile </button>
                                <div style={{ marginTop: -45, marginBottom: 40 }}>
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={healthprofile} />

                                </div>
                            </div>
                            <div onClick={accessHandeler}>

                                <button className="common-flex-button2" > Medical Records </button>
                                <div style={{ marginTop: -45, marginBottom: 40 }}>
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={medicalrecords} />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item ">
                        <div class="card-wrapper container-sm d-flex  justify-content-around">
                            <div onClick={accessHandeler}>

                                <button className="common-flex-button2"  >Your Consultation</button>
                                <div style={{ marginTop: -45, marginBottom: 40 }}>
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={consulthistory} />

                                </div>
                            </div>
                            <div onClick={accessHandeler}>
                                <button className="common-flex-button2" >  Doctors</button>

                                <div style={{ marginTop: -45, marginBottom: 40 }}>
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={doctorsicon} />

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <p style={{ color: 'red', fontSize: 16, textAlign: 'left', marginLeft: '14%', fontWeight: 'bold', marginTop: 10 }}>{msg}</p>

            <div className="divider"></div>
            <div className="latest-medical-records">
                <p style={{ fontSize: 17, fontWeight: 'bold', color: 'black' }}> Medical Tracking Info</p>

                <p style={{ fontSize: 17, color: '#000480', textAlign: 'left', paddingLeft: 20 }}>
                    Record your vitals here so that your latest vitals will always guide you for better health.
                </p>
                <p style={{ fontSize: 17, color: '#000480', textAlign: 'left', paddingLeft: 20 }}>Below chart is for representation only.
                </p>
                <div className='first-div' style={{ width: '317px' }}>

                    <LineChart
                        width={310}
                        height={300}
                        data={tableData}
                        margin={{
                            top: 5,
                            left: -20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="create_date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="Reading"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                        />

                    </LineChart>

                </div>
                {/* <div
                    className="latest-vital-cards"

                >
                    <div style={{ width: '55%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ textAlign: 'left' }}>
                            <img src={heart_rate} className='latest-vital-cards-img' />
                        </div>
                        <div style={{ textAlign: 'left' }}>

                            <p style={{ fontWeight: '600', fontSize: 13, marginLeft: 5 }}  >Heart Rate</p>
                        </div>
                    </div>
                    <div >
                        <div style={{ textAlign: 'right' }} >
                            <p className='latest-vital-cards-value'>100</p>
                        </div>
                        <div style={{ textAlign: 'right' }} >
                            <div className='flex-div' >
                                <p style={{ marginLeft: 5 }}> 11/09/2023 12:20</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="latest-vital-cards"

                >
                    <div style={{ width: '55%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ textAlign: 'left' }}>
                            <img src={body_temp} className='latest-vital-cards-img' />
                        </div>
                        <div style={{ textAlign: 'left' }}>

                            <p style={{ fontWeight: '600', fontSize: 13, marginLeft: 5 }}  >Body Temperature</p>
                        </div>
                    </div>
                    <div >
                        <div style={{ textAlign: 'right' }} >
                            <p className='latest-vital-cards-value'>80</p>
                        </div>
                        <div style={{ textAlign: 'right' }} >
                            <div className='flex-div' >
                                <p style={{ marginLeft: 5 }}> 11/09/2023 12:20</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="latest-vital-cards"

                >
                    <div style={{ width: '55%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ textAlign: 'left' }}>
                            <img src={blood_sugar} className='latest-vital-cards-img' />
                        </div>
                        <div style={{ textAlign: 'left' }}>

                            <p style={{ fontWeight: '600', fontSize: 13, marginLeft: 5 }}  >Blood Sugar</p>
                        </div>
                    </div>
                    <div >
                        <div style={{ textAlign: 'right' }} >
                            <p className='latest-vital-cards-value'>80/100</p>
                        </div>
                        <div style={{ textAlign: 'right' }} >
                            <div className='flex-div' >
                                <p style={{ marginLeft: 5 }}> 11/09/2023 12:20</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="latest-vital-cards"

                >
                    <div style={{ width: '55%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ textAlign: 'left' }}>
                            <img src={blood_pressure} className='latest-vital-cards-img' />
                        </div>
                        <div style={{ textAlign: 'left' }}>

                            <p style={{ fontWeight: '600', fontSize: 13, marginLeft: 5 }}  >Blood Pressure</p>
                        </div>
                    </div>
                    <div >
                        <div style={{ textAlign: 'right' }} >
                            <p className='latest-vital-cards-value'>120/80</p>
                        </div>
                        <div style={{ textAlign: 'right' }} >
                            <div className='flex-div' >
                                <p style={{ marginLeft: 5 }}> 11/09/2023 12:20</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default LandingPage
