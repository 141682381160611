import React, { useState } from 'react'
import back from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { useEffect } from 'react';
import Axios from 'axios';

const ContactUs = () => {
    let phoneno = localStorage.getItem("phoneNo");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const [message, setMessage] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [msg, setMsg] = useState("");
    const [patientDetails, setPatientDetails] = useState([]);


    const onEmailUpdate = (e) => {
        setEmail(e.target.value);
    }

    const onMessageUpdate = (e) => {
        setMessage(e.target.value);
    }
    const navigate = useNavigate()
    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/Tickets");
    }
    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientBioProfile/alldetails?phone=${phoneno}`).then((response) => {
            console.log("patient's all data", response.data);
            if (response.data.status == '1') {
                setPatientDetails(response.data.response);
                setName(response.data.response[0].first_name);


            }
        });
    }, [])
    const postHandler = (e) => {
        setButtonHide(true);
        e.preventDefault();
        console.log("fgg")
        setErrMsg("");

        if (email != "" && message != "" && name != "") {


            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append('name', name);
            formdata.append('email', email);
            formdata.append('phone_no', phoneno);
            formdata.append('message', message);
            $.ajax({
                type: "POST",
                url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientsSupport/create_ticket`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log("post successfully", data);
                    setMsg("Message send successfully");
                    setTimeout(navigate("/Tickets"), 2000);
                },
                error: function (err) {
                    console.log(err.responseText);
                    alert("Error has occured  ", err.responseText);
                }
            });


        }
        else {
            e.preventDefault();
            setErrMsg("All fields are required");
        }

    }

    return (
        <div>
            <div >

                <div style={{ textAlign: 'left' }}>
                    <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={backHandler} />
                </div>
                <p style={{ fontSize: 20, marginTop: -10, fontWeight: 'bold', color: '#A696FF' }}>New support ticket</p>

                <div className="login" >
                    <div class="fs-6 fw-light mb-2">Post your message below. We will get back to you ASAP via phone or email</div>
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        {/* <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>Name *</p>
                        <input type="text" name="Name" required placeholder="name "
                            value={name}
                            onChange={(event) => onNameUpdate(event)}
                        /> */}

                        <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>Email *</p>
                        <input type="email" name="email" required placeholder="email "
                            value={email}
                            maxLength={30}
                            onChange={(event) => onEmailUpdate(event)}
                        />
                        {/* <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>Phone *</p>
                        <input type="tel" name="phone" required placeholder="enter your login phone no"
                            value={phone}
                            onChange={(event) => onPhoneUpdate(event)}
                        /> */}
                        <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold' }}>Message *</p>
                        <textarea class="form-control"
                            id="message"
                            name="message"
                            rows="5"
                            value={message}
                            onChange={(event) => onMessageUpdate(event)}
                        >

                        </textarea>

                    </div>

                    {msg ? <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red', marginTop: 10 }}>{msg}</p> : null}
                    {errMsg ? <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red', marginTop: 10 }}>{errMsg}</p> : null}
                    <form method="post"
                        onSubmit={(event) => postHandler(event)}
                    >
                        {
                            buttonHide == false ?
                                <button type="submit" style={{ marginTop: 30, width: 320 }} className="btn btn-auto btn-primary">
                                    Post
                                </button>
                                :
                                <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold', marginTop: 15, marginLeft: 50 }}>Sending...</p>

                        }


                    </form>

                </div>
            </div>
        </div>

    )
}

export default ContactUs
