import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Search from '../assets/images/search.png';
import Axios from 'axios';
import heading from '../assets/images/heading.png';
import '../App.css';
import back from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";
import user from '../assets/images/user.png'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import $ from "jquery";
export default () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const [msg, setMsg] = useState("");
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [specialization, setSpecialization] = useState("");
    const [specializationDetails, setSpecializationDetails] = useState([]);
    const [doctorsName, setDoctorsName] = useState("");
    const [locationAddress, setLocationAddress] = useState("")
    const [doctorList, setDoctorList] = useState([])
    const location = useLocation();
    let pid = localStorage.getItem("patientid");
    // console.log(location.state);
    const navigate = useNavigate();

    const phoneNumberChangeHandler = (e) => {
        setPhoneNumber(e.target.value);

    }
    const specializationHandeler = (e) => {
        setSpecialization(e.target.value);
    }
    const locationChangeHandler = (e) => {
        setLocationAddress(e.target.value);

    }
    const nameChangeHandler = (e) => {
        setDoctorsName(e.target.value);

    }


    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/Doctors/get_patients_doctor_list?patientsid=${pid}`).then((response) => {
            console.log(response.data);
            if (response.data.status == '1') {
                setDoctorList(response.data.response);
            }
        });
        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/PickList/get_specialiazation`).then((response) => {
            console.log(response.data);
            if (response.data.status == '1') {
                setSpecializationDetails(response.data.response);
            }
        });


    }, [])
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };
    const addDoctors = (id) => {
        setDeleteOpen(true);
        //setUploadedDocId(id);
    };
    const addHandler = (e) => {
        setButtonHide(true)
        let id = localStorage.getItem("patientid");
        console.log(id);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('patients_id', pid);
        formdata.append('doctors_name', doctorsName);
        formdata.append('phone_no', phoneNumber);
        formdata.append('location', locationAddress);
        formdata.append('specialization', specialization);



        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/Doctors/add_patients_doctor`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log("updated successfully", data);
                setDeleteOpen(false);
                setButtonHide(false);
                setPhoneNumber("");
                setSpecialization("");
                setLocationAddress("");
                setDoctorsName("");
                Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/Doctors/get_patients_doctor_list?patientsid=${pid}`).then((response) => {
                    console.log(response.data);
                    if (response.data.status == '1') {
                        setDoctorList(response.data.response);
                    }
                });
            },
            error: function (err) {
                console.log(err.responseText);
                alert("Error has occured  ", err.responseText);
            }
        });
    };
    return (
        <div>

            <div style={{ textAlign: 'left' }}>
                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/")} />
            </div>
            <p style={{ fontSize: 20, marginTop: -10, fontWeight: 'bold', color: '#A696FF' }}> My Doctors</p>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>

                <p style={{ textAlign: 'left', fontSize: 15, fontWeight: '600', marginLeft: 20 }}>Click the + button to add doctors</p>
                <p onClick={addDoctors} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 15, color: 'blue', marginLeft: '12%' }}><button type="button" class="btn btn-primary" style={{ minWidth: 1, fontSize: 22 }}>+</button> </p>
            </div>
            <Dialog
                open={deleteOpen}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">

                    <div style={{ textAlign: 'right' }}     >

                        <Button style={{ color: 'red' }} onClick={handleDeleteClose}>CLOSE</Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p style={{ fontSize: 14, fontWeight: '600', textAlign: 'left' }} >Doctor's Name </p>
                        <div style={{ width: '250px', textAlign: 'left' }} >
                            <input
                                className="input_style"
                                value={doctorsName}
                                maxLength={24}
                                onChange={(event) => nameChangeHandler(event)}
                                type="text"
                                required

                            />
                        </div>
                        <p style={{ fontSize: 14, fontWeight: '600', textAlign: 'left' }} >Phone Number</p>
                        <div style={{ width: '250px', textAlign: 'left' }} >
                            <input
                                className="input_style"
                                value={phoneNumber}
                                maxLength={10}
                                onChange={(event) => phoneNumberChangeHandler(event)}
                                type="tel"
                                required

                            />
                        </div>
                        <p style={{ fontSize: 14, fontWeight: '600', textAlign: 'left' }} >Specialization</p>
                        <div >
                            <select
                                name="specialization"
                                className="input_style"
                                value={specialization}
                                onChange={(event) => specializationHandeler(event)}
                            >
                                <option value="">Select</option>
                                {specializationDetails.map((val) => {
                                    return (
                                        <>
                                            <option value={val.name} >{val.name} </option>
                                        </>
                                    );
                                })}

                            </select>
                        </div>
                        <p style={{ fontSize: 14, fontWeight: '600', textAlign: 'left' }} >Location </p>
                        <div style={{ width: '250px', textAlign: 'left' }} >
                            <input
                                className="input_style"
                                value={locationAddress}
                                maxLength={30}
                                onChange={(event) => locationChangeHandler(event)}
                                type="text"
                                required

                            />
                        </div>
                        <form method="post"
                            onSubmit={(event) => addHandler(event)}
                        >
                            {
                                buttonHide == false ?
                                    <button type="submit" style={{ marginTop: 30, textAlign: 'center' }} className="btn btn-auto btn-primary">
                                        Add
                                    </button>
                                    :
                                    <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold', marginTop: 15 }}>Add...</p>

                            }


                        </form>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>



                </DialogActions>
            </Dialog>
            <div style={{ marginTop: 20 }}>
                {
                    doctorList.length > 0 ?


                        doctorList.map((result) => {
                            return (
                                <div style={{ marginTop: 15 }}>
                                    <div className="doctors-card" key={result.doctorsid}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ width: '25%', }}>

                                                <img src={user} style={{ height: 60, width: 60, marginTop: 5, borderRadius: 40 }} />

                                            </div>
                                            <div style={{ width: '75%', textAlign: 'left', marginLeft: 10 }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <p style={{ fontSize: 17, fontWeight: '600' }}>{result.doctors_name} </p>

                                                </div>
                                                <p style={{ fontSize: 14, marginTop: -3 }}>{result.phone_no}</p>
                                                <p style={{ fontSize: 14, marginTop: -8 }}>{result.location} </p>
                                                <p style={{ fontSize: 14, marginTop: -8 }}>{result.specialization}</p>
                                                {/* <p style={{ fontSize: 14, marginTop: -8 }}>{result.experience}</p> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                        :
                        <>
                            <p style={{ marginTop: 60, fontSize: 16, fontWeight: 'bold', color: 'red' }}>No doctor's found</p>

                        </>


                }
                {msg ? <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red', marginTop: 20 }}>{msg}</p> : null}
            </div>

        </div>


    )
}