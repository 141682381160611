import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import '../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import heading from '../assets/images/heading.png';

export default () => {

    const [phoneNo, setPhoneNo] = useState("");
    const [sendingOTP, setSendingOTP] = useState(false);
    const [buttonHide, setButtonHide] = useState(false);
    const [verifyButtonHide, setVerifyButtonHide] = useState(false);
    const [OTPinput, setOTPInput] = useState("");
    const [OTPbackendResponse, setOTPBackendResponse] = useState("");

    const [isEmptyPhoneNo, setIsEmptyPhoneNo] = useState(false);
    const [OTPsectionHide, setOTPSectionHide] = useState(false);
    const [OTPVerificationsectionHide, setOTPVerificationSectionHide] = useState(false);
    const [msg, setMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [patientDetails, setPatientDetails] = useState([]);
    const [medicaltrackingbt, setMedicaltrackingbt] = useState([]);
    const [isBtRecord, setIsBtRecord] = useState(false);
    const [medicaltrackingbs, setMedicaltrackingbs] = useState([]);
    const [isBsRecord, setIsBsRecord] = useState(false);
    const [medicaltrackingbp, setMedicaltrackingbp] = useState([]);
    const [medicaltrackinghr, setMedicaltrackinghr] = useState([]);
    const [isBpRecord, setIsBpRecord] = useState(false);
    const [isHrRecord, setIsHrRecord] = useState(false);
    const [OTPvalidated, setOTPValidated] = useState(true);
    const [yourOTP, setYourOTP] = useState("");






    const navigate = useNavigate();
    const location = useLocation();

    const phoneNoChangeHandler = (e) => {
        setPhoneNo(e.target.value);

    }

    const OTPChangeHandler = (e) => {
        setOTPInput(e.target.value);
    }


    const sendOTPHandler = () => {
        if (phoneNo != "") {
            setSendingOTP(true);
            setButtonHide(true);
            setIsEmptyPhoneNo(false);
            setMsg("")

            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/Patients/validate_phone?phone_number=${phoneNo}`)
                .then(response => {
                    console.log(response.data);
                    if (response.data != null) {
                        console.log(response.data.phone_number);
                        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/Misc/sendotp?phone=${response.data.phone_number}`)
                            .then(OTPresponse => {
                                setYourOTP(OTPresponse.data);
                                console.log('sendOTPHandler:otp response \n', OTPresponse.data);
                                setSendingOTP(false);
                                setButtonHide(false);
                                if (OTPresponse.data) {
                                    setOTPBackendResponse(OTPresponse.data);
                                    setOTPSectionHide(true);
                                }

                            }).catch((err) => {
                                console.log('Reset Password: otp send failed', err);
                                setSendingOTP(false);
                                setButtonHide(false);

                            })

                    } else {
                        setSendingOTP(false);

                        setButtonHide(false);
                        setMsg("This no is not registered.Please check the number.")
                    }


                }).catch((err) => {
                    console.log('error', err);


                })


        } else {
            setIsEmptyPhoneNo(true);
            setButtonHide(false);

        }
    }
    let id;
    const verifyOTPHandler = () => {
        setVerifyButtonHide(true);
        setSendingOTP(true);
        if (OTPbackendResponse == OTPinput) {
            console.log(phoneNo)
            localStorage.setItem('phoneNo', phoneNo);
            setSuccessMsg("Verified! MyMedical Records is showing");
            setMsg("");
            setVerifyButtonHide(true);
            setSendingOTP(false);
            setOTPVerificationSectionHide(true);
            navigate("/");




        } else {
            setMsg("OTP is not matching");
            setSuccessMsg("");
            setVerifyButtonHide(false);
            setSendingOTP(false);
        }
    }

    return (
        <>
            <div className="login" style={{ paddingTop: 10 }}
            // style={{ border: '1px solid black', borderRadius: 10, height: 300, width: 'auto', marginTop: 60, marginLeft: 20, marginRight: 20 }}
            >
                <div style={{ backgroundColor: 'white', height: '70%' }}>
                    <img className="image" src={heading} style={{ height: 80, width: '100%' }} />

                    {OTPsectionHide == false ?
                        <>
                            <div>

                                <h3 style={{ textAlign: 'center', marginTop: 20, fontWeight: 'bold', fontSize: 20 }}>SIGN IN</h3>
                            </div>


                            <div style={{ textAlign: 'center' }}>
                                <input onChange={(event) => phoneNoChangeHandler(event)}
                                    style={{ border: '1px solid rgb(185, 184, 184)', backgroundColor: '#DDDDDD', color: 'black', width: '40%', height: 40, paddingLeft: 8, fontSize: 12, borderRadius: 8, marginTop: 50, fontSize: 'small', width: '60%', textAlign: 'center', }} type="number" placeholder="Enter phone number" />
                            </div>

                            <div style={{ marginTop: 5, textAlign: 'center' }}>

                                {
                                    isEmptyPhoneNo ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}>Please enter phone no</p>
                                        :
                                        null
                                }
                                {msg ? <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red', marginTop: 10 }}>{msg}</p> : null}


                                {
                                    buttonHide == false ?
                                        <button className="otp_button" onClick={sendOTPHandler} >SEND OTP</button>
                                        :
                                        null
                                }





                                {
                                    sendingOTP ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: '#6080eeed' }}>Sending...</p>
                                        :
                                        null
                                }

                            </div>

                        </>
                        :
                        null


                    }

                    {
                        OTPsectionHide == true && OTPVerificationsectionHide == false ?
                            <div style={{ textAlign: 'center' }}>

                                {
                                    yourOTP ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: '#6080eeed', marginTop: 20 }}>Your OTP IS : {yourOTP}</p>
                                        : null

                                }
                                <div>
                                    <input onChange={(event) => OTPChangeHandler(event)}
                                        style={{ border: '1px solid rgb(185, 184, 184)', backgroundColor: '#DDDDDD', color: 'black', width: '40%', height: 30, paddingLeft: 8, fontSize: 12, borderRadius: 8, marginTop: 50, fontSize: 'small' }} type="number" placeholder="Enter your 6 digit OTP" />
                                </div>
                                {
                                    verifyButtonHide == false ?
                                        <button className="otp_button" onClick={verifyOTPHandler} >VERIFY OTP</button>
                                        :
                                        null
                                }


                                {
                                    sendingOTP ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: '#6080eeed' }}>Verifing...</p>
                                        :
                                        null
                                }
                                {<p style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}>{msg}</p>}

                            </div>
                            : null
                    }
                </div>
            </div>
        </>

    )
}