import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import back from '../assets/images/back.png'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import allergy from '../assets/images/allergy.jpg';
import surgery from '../assets/images/surgery.jpg';
import illness from '../assets/images/illness.jpg';
import eyepower from '../assets/images/eyepower.jpg';
import injury from '../assets/images/injury.jpg';
import smoking from '../assets/images/smoking.png';
import alcohol from '../assets/images/alcohol.jpg';
import activity from '../assets/images/activity.jpg';
import workpattern from '../assets/images/workpattern.jpg';
import diet from '../assets/images/diet.png';
import edit from '../assets/images/edit.png';
import '../App.css';
import $ from "jquery";
import { FcPlus } from "react-icons/fc";
import deleteicon from '../assets/images/delete-icon.jpg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import height from '../assets/images/weight.png';
import weight from '../assets/images/height.png';
import Spinner from '../components/Spinner';
const moment = require('moment');
export default () => {
    const [healthData, setHealthData] = useState([]);
    const [familyHistory1Dis, setFamilyHistory1Dis] = useState([]);
    const [familyHistory1Rel, setFamilyHistory1Rel] = useState([]);
    const [familyHistory2Dis, setFamilyHistory2Dis] = useState([]);
    const [familyHistory2Rel, setFamilyHistory2Rel] = useState([]);
    const [familyHistory, setFamilyHistory] = useState([]);
    const [allergicInformation, setAllergicInformation] = useState([]);
    const [chronicillness, setChronicillness] = useState([]);
    const [vaccinationDetails, setVaccinationDetails] = useState([]);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [isDeleteDoc, setIsDeleteDoc] = useState(false);
    const [relation, setRelation] = useState([]);
    const [familyHistoryDisease, setFamilyHistoryDisease] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    let id

    useEffect(() => {
        id = localStorage.getItem("patientid");
        console.log(id)
        if (localStorage.getItem("chronic_illness_data") != "" && localStorage.getItem("allergic_information_data") != "" && localStorage.getItem("family_relation_data") != "" && localStorage.getItem("family_disease_data") != "") {


            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/PickList/get_chronic_illness`).then((response) => {
                console.log(response.data);

                if (response.data.status == '1') {
                    //setAllergicInformation(response.data.response);
                    localStorage.setItem("chronic_illness_data", JSON.stringify(response.data.response));
                    setChronicillness(response.data.response);
                }
            });

            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/PickList/get_allergies`).then((response) => {
                console.log(response.data);
                if (response.data.status == '1') {
                    //setChronicillness(response.data.response);
                    localStorage.setItem("allergic_information_data", JSON.stringify(response.data.response))
                    setAllergicInformation(response.data.response);
                }
            });

            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/PickList/get_family_ralation`).then((response) => {
                console.log(response.data);
                if (response.data.status == '1') {
                    localStorage.setItem("family_relation_data", JSON.stringify(response.data.response))
                    setRelation(response.data.response);
                }
            });
            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/PickList/get_family_disease`).then((response) => {
                console.log(response.data);
                if (response.data.status == '1') {
                    localStorage.setItem("family_disease_data", JSON.stringify(response.data.response))
                    setFamilyHistoryDisease(response.data.response);
                }
            });
        }
        else {
            setChronicillness(JSON.parse(localStorage.getItem("chronic_illness_data")))
            setAllergicInformation(JSON.parse(localStorage.getItem("allergic_information_data")));
            setRelation(JSON.parse(localStorage.getItem("family_relation_data")));
            setFamilyHistoryDisease(JSON.parse(localStorage.getItem("family_disease_data")));
        }


        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthProfile/get?patients_id_fk=${id}`).then((response) => {
            console.log(response.data);
            setLoading(false);
            if (response.data != null) {
                if (response.data.status == '1') {
                    setHealthData(response.data.response);
                    if (response.data.response.family_history_1_disease || response.data.response.family_history_2_disease != "") {
                        setFamilyHistory1Dis(response.data.response.family_history_1_disease);
                        setFamilyHistory1Rel(response.data.response.family_history_1_relation);
                        setFamilyHistory2Dis(response.data.response.family_history_2_disease);
                        setFamilyHistory2Rel(response.data.response.family_history_2_relation);

                    }

                }

            }
        });

        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientVaccinationInfo/get_vaccination_info_by_patientid?patient_id=${id}`).then((response) => {
            console.log(response.data);
            if (response.data.status == '1') {
                setVaccinationDetails(response.data.response)
            }
        });


    }, [])
    const handleDeleteClickOpen = (id) => {
        setDeleteOpen(true);
    };
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/");
    }
    const deleteDocumentsHandler = (e, id) => {
        setIsDeleteDoc(true);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('vaccination_id', id);

        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientVaccinationInfo/delete_vaccination_info_by_id`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log("deleted document response", data);
                alert("Vaccination deleted");
                setIsDeleteDoc(false);
                setDeleteOpen(false);

                window.location.reload(false);


            },
            error: function (err) {
                console.log(err.responseText);
                setIsDeleteDoc(false);
                alert("Error has occured  ", err.responseText);
            }
        });
    }
    return (
        <>
            {
                loading ? <Spinner /> :
                    <>



                        <div style={{ textAlign: 'left' }}>

                            <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={backHandler} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>Health-Profile</p>
                            <img src={edit} style={{ height: 30, width: 30, marginLeft: 20, marginTop: -8 }} onClick={() => navigate("/EditHealthProfile", { state: healthData })} />

                        </div>

                        <div className='first-div'>
                            <div >
                                <div style={{ textAlign: 'left', paddingLeft: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }} > <img src={allergy} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Allergy:</p>
                                        {allergicInformation.map((val) => {
                                            return (
                                                <>
                                                    {
                                                        val.code == healthData.allergy_info ?
                                                            <p style={{ fontSize: 16, marginTop: 12, height: 24, width: '50%', textAlign: 'right', paddingRight: 20 }} >{val.value}</p> : null
                                                    }
                                                </>

                                            )
                                        })}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={illness} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Chronic Illness :</p>
                                        {chronicillness.map((val) => {
                                            return (
                                                <>
                                                    {
                                                        val.code == healthData.chronic_illness ?
                                                            <p style={{ fontSize: 16, marginTop: 12, height: 31, width: '50%', textAlign: 'right', paddingRight: 20 }} >{val.value}</p> : null
                                                    }
                                                </>

                                            )
                                        })}

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={injury} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Major Injury :</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 29, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.major_injury}</p>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={surgery} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Major Surgery :</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 23, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.major_surgery}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={eyepower} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Eye Power :</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 23, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.eyepower}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={weight} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Weight :</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 23, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.weight}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={height} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Height :</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 23, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.height}</p>
                                    </div>
                                </div>

                            </div>



                        </div>


                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: 20 }}>Life-Style</p>
                            <img src={edit} style={{ height: 30, width: 30, marginLeft: 20, marginTop: 20 }} onClick={() => navigate("/EditLifeStyle", { state: healthData })} />

                        </div>
                        <div className='first-div'>
                            <div >
                                <div style={{ textAlign: 'left', paddingLeft: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={smoking} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Smoking:</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 24, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.smoke}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={alcohol} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Alcohol:</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 31, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.alcohol}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={activity} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Exercise:</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 29, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.physical_activity}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={workpattern} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Work Pattern:</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 23, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.workpattern}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)', width: '50%' }}><img src={diet} style={{ height: 16, width: 16, borderRadius: 4 }} />&nbsp;&nbsp;Diet:</p>
                                        <p style={{ fontSize: 16, marginTop: 12, height: 23, width: '50%', textAlign: 'right', paddingRight: 20 }}>{healthData.diet}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: 20 }}>Family History</p>
                            <img src={edit} style={{ height: 30, width: 30, marginLeft: 20, marginTop: 20 }} onClick={() => navigate("/EditFamilyHistory", { state: healthData })} />
                        </div>
                        <div className='first-div'>

                            <table id='prescriptiondata' style={{ width: '97%', paddingRight: 5, borderRadius: 5, marginLeft: 5 }} >
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>Disease</th>
                                        <th style={{ textAlign: 'left' }} >Relation with Patient</th>
                                    </tr>
                                </thead>


                                <tbody>

                                    <td className="value" style={{ textAlign: 'left' }} >
                                        {familyHistoryDisease.map((val) => {
                                            return (
                                                <>
                                                    {
                                                        val.code == familyHistory1Dis ?
                                                            <p  >{val.value}</p> : null
                                                    }
                                                </>

                                            )
                                        })}
                                    </td>
                                    <td className="value" style={{ textAlign: 'left' }}>

                                        {relation.map((val) => {
                                            return (
                                                <>
                                                    {
                                                        val.code == familyHistory1Rel ?
                                                            <p  >{val.value}</p> : null
                                                    }
                                                </>

                                            )
                                        })}
                                    </td>



                                </tbody>
                                <tbody>

                                    <td className="value" style={{ textAlign: 'left' }} >

                                        {familyHistoryDisease.map((val) => {
                                            return (
                                                <>
                                                    {
                                                        val.code == familyHistory2Dis ?
                                                            <p  >{val.value}</p> : null
                                                    }
                                                </>

                                            )
                                        })}
                                    </td>
                                    <td className="value" style={{ textAlign: 'left' }}>

                                        {relation.map((val) => {
                                            return (
                                                <>
                                                    {
                                                        val.code == familyHistory2Rel ?
                                                            <p >{val.value}</p> : null
                                                    }
                                                </>

                                            )
                                        })}
                                    </td>



                                </tbody>


                            </table>




                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: 20 }}>Vaccination</p>
                            <FcPlus size={30} onClick={() => navigate("/EditVaccination", { state: id })} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 15, color: 'blue', marginLeft: 10, marginTop: 25 }} />
                        </div>


                        <div className='first-div'>
                            <div style={{ marginTop: 5 }} className="medicineview">
                                <table id='prescriptiondata' style={{ width: '100%', paddingRight: 5, borderRadius: 5, marginLeft: 5, marginRight: 5 }} >
                                    <thead>
                                        <tr>
                                            <th style={{ width: '400px', textAlign: 'left' }} >Name</th>
                                            <th style={{ width: '50px', textAlign: 'left' }}>Dose</th>
                                            <th style={{ width: '350px', textAlign: 'left' }}>Date</th>
                                            <th style={{ width: '50px', textAlign: 'left' }}>Action</th>

                                        </tr>
                                    </thead>

                                    {
                                        vaccinationDetails.map((val) => {
                                            return (
                                                <> <tbody>
                                                    <td style={{ textAlign: 'left', width: 10 }} className="value">{val.name}</td>
                                                    <td style={{ textAlign: 'left' }} className="value"> {val.dose}</td>
                                                    <td style={{ textAlign: 'left' }} className="value">{moment(val.date).format('DD-MM-YYYY')}</td>
                                                    <td><img src={deleteicon} style={{ height: 30, width: 30, marginTop: 5 }} onClick={() => { handleDeleteClickOpen(val.id) }} /></td>
                                                    <Dialog
                                                        open={deleteOpen}
                                                        onClose={handleDeleteClose}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                    >
                                                        <DialogTitle id="alert-dialog-title">
                                                            {"Are you sure you want to delete this document?"}
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">

                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleDeleteClose}>Cancel</Button>
                                                            {
                                                                isDeleteDoc == false ?
                                                                    <form
                                                                        method="post"
                                                                        onSubmit={(event) => deleteDocumentsHandler(event, val.id)}

                                                                    >
                                                                        <Button type="submit" autoFocus>
                                                                            Delete
                                                                        </Button>
                                                                    </form>
                                                                    :
                                                                    <p style={{ color: 'red', fontSize: 15, fontWeight: 'bold', marginLeft: 10, marginTop: -5 }}>Deleting...</p>
                                                            }

                                                        </DialogActions>
                                                    </Dialog>
                                                </tbody>
                                                </>
                                            )
                                        })
                                    }



                                </table>
                            </div>

                        </div>
                        <div style={{ paddingBottom: 40 }}>

                        </div>
                    </>
            }
        </>
    )
}