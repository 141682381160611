import React, { useState, useEffect, useRef, useCallback } from 'react';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import back from '../assets/images/back.png';
import { useRecoilState } from 'recoil';
import { patient_profile_image_show } from '../Store/PatientProfileDetails'
import { BiFolderPlus } from "react-icons/bi";
import imageCompression from 'browser-image-compression';
import Webcam from 'react-webcam';
import camera from '../assets/images/camera.png';
import cameraSwitch from '../assets/images/cameraSwitch.png';
import upload from '../assets/images/upload1.png';
import folder from '../assets/images/folder1.png';
import { Camera, CameraResultType } from '@capacitor/camera';
import { CameraSource } from '@capacitor/camera';


export default () => {
    const [picture, setPicture] = useState(null);
    const [imgData, setImgData] = useState("");
    const [isSelectedImage, setIsSelectedImage] = useState(false);
    const [msg, setMsg] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [profileImgShow, setProfileImgShow] = useRecoilState(patient_profile_image_show);


    let compressedFile;
    let url;
    console.log(location.state);

    const uploadPhotoHandler = (e) => {

        setButtonHide(true);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append("file", imgData);
        formdata.append('patientid', location.state.up_id);
        formdata.append('flag', "P");//P for profile
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/FileUpload/file_insert_patientapp`,
            data: formdata,
            processData: false,
            contentType: false,
            success(uploadedimage) {
                console.log(uploadedimage)
                if (uploadedimage) {
                    formdata.append("profile_image", uploadedimage);
                    formdata.append('patientid', location.state.up_id);
                    $.ajax({
                        type: "POST",
                        url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientBioProfile/save_patient_profile`,
                        data: formdata,
                        processData: false,
                        contentType: false,
                        success(data) {
                            console.log(data);
                            if (data) {
                                setButtonHide(false);
                                setMsg("Updated successfully");
                                setProfileImgShow(true);
                                setTimeout(() => {
                                    navigate("/");
                                }, 2000)


                            }

                        },
                        error: function (err) {
                            console.log(err);
                            setButtonHide(false);

                        }
                    });

                }

            },
            error: function (err) {
                console.log(err);
                setButtonHide(false);

            }
        });



    }
    const fileInputRef = useRef();
    const triggerUpload = () => {
        fileInputRef.current.click()
    }
    const fileChangeHandler = async (event) => {
        console.log("fileChangeHandler: selectedfile  ", event.target.files[0])

        const options1 = {
            maxSizeMB: 0.2,
            // maxWidthOrHeight: 1000,
            useWebWorker: true,
        };




        try {
            if (event.target.files[0].size > 512000) {
                compressedFile = await imageCompression(event.target.files[0], options1);
                console.log("compressed file ", compressedFile)
                const file = new File([compressedFile], `${compressedFile.name}.jpeg`, { type: "image/jpeg" })
                setImgData(file);
                url = URL.createObjectURL(compressedFile)
            }
            else {
                setImgData(event.target.files[0]);
                url = URL.createObjectURL(event.target.files[0])
            }



            console.log("url", url)
            setPicture(url)
            setIsSelectedImage(true);
        }
        catch (error) {
            console.log(error);
        }

    }

    const startCam = async () => {

        const image = await Camera.getPhoto({
            quality: 40,
            allowEditing: true,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt
        });
        const options1 = {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 1000,
            useWebWorker: true,
        };
        let url = image.dataUrl;
        setPicture(url);
        fetch(url)
            .then(res => res.blob())
            .then(blob => {
                compress(blob)
                console.log("orginal file ", blob);
            })

        const compress = async (blob) => {
            try {

                if (blob.size > 512000) {
                    compressedFile = await imageCompression(blob, options1);
                    console.log("compressed file ", compressedFile)
                    const file = new File([compressedFile], `${compressedFile.name}.jpeg`, { type: "image/jpeg" })
                    setImgData(file);
                    url = URL.createObjectURL(compressedFile)
                    setPicture(url)

                }
                else {

                    const file = new File([blob], "Filename.jpeg", { type: "image/jpeg" })
                    console.log("camear: selectedfile  ", file);
                    setPicture(url)
                    setIsSelectedImage(true);
                    setImgData(file);

                }
                setIsSelectedImage(true);

            }

            catch (error) {
                console.log(error);
            }

        }
    }
    return (

        <div className="page-user">

            <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/EditProfile")} />

            <div style={{ marginTop: 5 }} className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">



                        </div>
                        <div className="col-xl-4 col-lg-4">

                            <h6 className="card-title">Edit Photo</h6>

                        </div>
                        <div className="col-xl-4 col-lg-4">


                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container"  >
                    <div style={{ marginTop: -10 }} className="row">

                        <div className="col-xl-12 col-lg-12">

                            <div style={{ padding: 10, height: 550, backgroundColor: 'white', marginTop: 30 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <img style={{ height: 400, width: 600, marginLeft: 'auto', marginRight: 'auto', border: '0.1px solid blue' }} src={picture} />


                                </div >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div onClick={startCam} style={{ width: '30%' }}  >
                                        <img src={camera} style={{ height: 40, width: 40, marginTop: 15 }} />
                                        <p style={{ fontSize: 13, fontWeight: '600', marginTop: 16, }}>Camera</p>
                                    </div>
                                    <input className="fileinputstyle" style={{ marginTop: 15, width: '25%' }} type="file" name="mfile" id="mfile" ref={fileInputRef} onChange={(event) => fileChangeHandler(event)} />

                                    <img style={{ marginTop: 15, height: 39, marginLeft: -65, width: 40 }} onClick={triggerUpload} src={folder} />

                                    {isSelectedImage ?
                                        <>
                                            <div style={{ textAlign: 'left', marginLeft: 85, marginTop: -60 }}>
                                                <form
                                                    method="post"

                                                >
                                                    {buttonHide == false ?
                                                        <div style={{ cursor: 'pointer' }} onClick={(event) => uploadPhotoHandler(event)} >
                                                            <img src={upload} style={{ marginTop: 75, height: 45, width: 45, cursor: 'pointer' }} />
                                                            <p style={{ fontSize: 13, fontWeight: '600', marginTop: 10, marginLeft: 3 }}>Upload</p>
                                                        </div>

                                                        :
                                                        <p style={{ color: 'green', fontSize: 13, marginTop: 75 }}>Uploading...</p>
                                                    }
                                                </form>
                                            </div>

                                        </>
                                        :
                                        null


                                    }

                                </div>
                                <p style={{ fontSize: 13, fontWeight: '600', marginTop: -22, marginLeft: -62 }}>My files</p>
                                {msg ? <p style={{ color: 'green', fontSize: 13, fontWeight: 'bold', marginTop: 25 }}>{msg}</p> : null}
                            </div>


                        </div>
                    </div>
                </div>
            </div>





        </div>

    )
}