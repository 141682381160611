import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import back from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Axios from "axios"
import Spinner from '../components/Spinner';

export default () => {
    const [allergy, setAllergy] = useState("");
    const [chronicIll, setChronicIll] = useState("");
    const [majorInjury, setMajorInjury] = useState("");
    const [majorSurgery, setMajorSurgery] = useState("");
    const [eyepower, setEyePower] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const [seesionId, setSessionId] = useState(Number);
    const [allergicInformation, setAllergicInformation] = useState([]);
    const [chronicillness, setChronicillness] = useState([]);
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const location = useLocation();
    console.log(location.state)
    const navigate = useNavigate();

    useEffect(() => {
        let allergic = JSON.parse(localStorage.getItem("allergic_information_data"))

        setAllergicInformation(allergic);
        let chronicillness = JSON.parse(localStorage.getItem("chronic_illness_data"))

        setChronicillness(chronicillness);


        let id = localStorage.getItem("patientid");
        console.log(id);
        setSessionId(id);
        console.log(location.state);


        if (location.state.allergy_info == null) {
            setAllergy("");
        }
        else {

            setAllergy(location.state.allergy_info);
            console.log(location.state.allergy_info);
        }
        if (location.state.chronic_illness == null) {
            setChronicIll("");
        }
        else {
            setChronicIll(location.state.chronic_illness);
        }
        if (location.state.major_injury == null) {
            setMajorInjury("");
        }
        else {
            setMajorInjury(location.state.major_injury);
        }


        if (location.state.major_surgery == null) {
            setMajorSurgery("");
        }
        else {
            setMajorSurgery(location.state.major_surgery);
        }

        if (location.state.eyepower == null) {
            setEyePower("");
        }
        else {
            setEyePower(location.state.eyepower);
        }
        if (location.state.weight == null) {
            setWeight("");
        }
        else {
            setWeight(location.state.weight);
        }
        if (location.state.height == null) {
            setHeight("");
        }
        else {
            setHeight(location.state.height);
        }



    }, []);

    const handleChangeAllery = (event) => {

        setAllergy(event.target.value);
    }
    const handleChangeChronicIllness = (event) => {

        setChronicIll(event.target.value);
    }
    const handleChangeMajorInjury = (event) => {

        setMajorInjury(event.target.value);
    }
    const handleChangeMajorSurgery = (event) => {

        setMajorSurgery(event.target.value);
    }
    const handleChangeEyepower = (event) => {

        setEyePower(event.target.value);
    }
    const handleChangeWeight = (event) => {

        setWeight(event.target.value);
    }
    const handleChangeHeight = (event) => {

        setHeight(event.target.value);
    }




    let commonValue = [
        {
            id: 1,
            key: 'Yes',
            value: 'Yes',

        },
        {
            id: 2,
            key: 'No',
            value: 'No',

        },
    ]









    const updateHandler = (e) => {
        setButtonHide(true);
        // console.log(seesionId)

        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('id', seesionId);
        formdata.append('allergy_info', allergy);
        formdata.append('chronic_illness', chronicIll);
        formdata.append('major_injury', majorInjury);
        formdata.append('major_surgery', majorSurgery);
        formdata.append('eyepower', eyepower);
        formdata.append('weight', weight);
        formdata.append('height', height);


        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthProfile/update`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log("updated successfully", data);
                setButtonHide(false);
                navigate("/PatientHealthProfile");


            },
            error: function (err) {
                console.log(err.responseText);
                alert("Error has occured  ", err.responseText);
            }
        });

    }



    return (
        <div>
            <div style={{ textAlign: 'left' }}>

                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/PatientHealthProfile")} />
            </div>
            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>Edit Health Profile</p>

            <div className='first-div'>
                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" >Allergy:</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="allergy"
                                value={allergy}
                                onChange={(event) => handleChangeAllery(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            >
                                <option value="" >select</option>
                                {
                                    allergicInformation.map((val) => {
                                        return (

                                            <option value={val.code} >{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>

                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" >Chronic Illness:</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="chronicIll"
                                value={chronicIll}
                                onChange={(event) => handleChangeChronicIllness(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            ><option value="">select</option>
                                {
                                    chronicillness.map((val) => {
                                        return (

                                            <option value={val.code}>{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>

                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" >Major Injury:</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="majorInjury"
                                value={majorInjury}
                                onChange={(event) => handleChangeMajorInjury(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            ><option value="">select</option>
                                {
                                    commonValue.map((val) => {
                                        return (

                                            <option value={val.value} >{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>

                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" >Major Surgery:</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="majorSurgery"
                                value={majorSurgery}
                                onChange={(event) => handleChangeMajorSurgery(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            ><option value="">select</option>
                                {
                                    commonValue.map((val) => {
                                        return (

                                            <option value={val.value}>{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>

                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" >Eye Power:</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="eyepower"
                                value={eyepower}
                                onChange={(event) => handleChangeEyepower(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            ><option value="">select</option>
                                {
                                    commonValue.map((val) => {
                                        return (

                                            <option value={val.value}>{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>
                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" >Weight:</p>
                    </div>
                    <div style={{ width: '57%' }}>


                        <input
                            name="eyepower"
                            value={weight}
                            onChange={(event) => handleChangeWeight(event)}
                            type='tel'
                            className="input-bordered"
                            style={{ fontSize: 13, fontWeight: 'bold' }}
                            maxLength={3}
                        >
                        </input>

                    </div>

                </div>
                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" >Hight:</p>
                    </div>
                    <div style={{ width: '57%' }}>


                        <input
                            name="eyepower"
                            value={height}
                            onChange={(event) => handleChangeHeight(event)}
                            type='tel'
                            className="input-bordered"
                            style={{ fontSize: 13, fontWeight: 'bold' }}
                            maxLength={3}
                        >
                        </input>

                    </div>

                </div>


                <form method="post"
                    onSubmit={(event) => updateHandler(event)}
                >
                    {
                        buttonHide == false ?
                            <button style={{ marginTop: 30 }} type="submit" className="btn btn-auto btn-primary">
                                Update
                            </button>
                            :
                            <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold', marginTop: 15 }}>Updating...</p>

                    }


                </form>

            </div>
        </div>
    )

}