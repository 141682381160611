import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import back from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Axios from 'axios';
import { useRecoilState } from 'recoil';
import { patient_medical_data_loading } from '../Store/PatientProfileDetails'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
const moment = require('moment');
const Editvitals = () => {

    const location = useLocation();
    const [readingValue, setReadingValue] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const today = new Date();
    const numberOfDaysToAdd = 0;
    const date1 = today.setDate(today.getDate() + numberOfDaysToAdd);
    const defaultValue = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
    const [date, setDate] = useState(defaultValue);
    var hour = today.getHours();
    var min = today.getMinutes();
    hour = (hour < 10 ? "0" : "") + hour;
    min = (min < 10 ? "0" : "") + min;
    var displayTime = hour + ":" + min;
    const [time, setTime] = useState(displayTime);
    const [vitalsOnSelectTrackingCode, setVitalsOnSelectTrackingCode] = useState("");
    const [tableData, setTableData] = useState([]);
    const [trackingId, setTrackingId] = useState('');
    const [trackingType, setTrackingType] = useState('');
    let pid = localStorage.getItem("patientid");
    const [msg, setMsg] = useState(false);
    const [medicalTrackingDataShow, setMedicalTrackingDataShow] = useRecoilState(patient_medical_data_loading);
    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/");
    }
    useEffect(() => {

        if (location.state.orginalValue) {
            if (location.state.orginalValue != '') {
                console.log(location.state);
                setTrackingId(location.state.orginalValue.tracking_id);
                setTrackingType(location.state.orginalValue.tracking_type);
                Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_medical_tracking_data_by_trackingid?trackingid=${location.state.orginalValue.tracking_id}&patientid=${pid}`)

                    .then((response) => {

                        if (response.data.status == '1') {
                            console.log(response.data.response)
                            setTableData(response.data.response);
                            setMsg(false);
                        }
                        else {
                            setTableData([]);
                            setMsg(true);
                        }

                    });
            }
        }


    }, []);

    const navigate = useNavigate();
    const onReadingValueUpdate = (event) => {
        setReadingValue(event.target.value);


    }
    const handleChangeDate = (event) => {

        setDate(event.target.value);
    }
    const handleChangeTime = (event) => {

        setTime(event.target.value);
    }

    const updateHandler = (e) => {
        let id = localStorage.getItem("patientid");
        console.log(id);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('patients_id_fk', pid);
        formdata.append('tracking_id_fk', trackingId);
        //formdata.append('tracking_code_fk', vitalsOnSelectTrackingCode);
        formdata.append('value', readingValue);
        formdata.append('tracking_datetime', date + " " + time);
        console.log(date + " " + time)
        //formdata.append('time', time);
        console.log("vital details : ", trackingId, vitalsOnSelectTrackingCode)


        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/add_medical_tracking_data`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log("updated successfully", data);
                Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_medical_tracking_data_by_trackingid?trackingid=${trackingId}&patientid=${pid}`)

                    .then((response) => {
                        if (response.data.status == '1') {
                            console.log(response.data)
                            setTableData(response.data.response)
                            setMedicalTrackingDataShow(true);
                            setMsg(false);

                        }
                    });
                setReadingValue('')


            },
            error: function (err) {
                console.log(err.responseText);
                alert("Error has occured  ", err.responseText);
            }
        });

    }

    return (
        <div>

            <div style={{ textAlign: 'left' }}>
                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={backHandler} />
            </div>
            <p style={{ fontSize: 20, marginTop: -10, fontWeight: 'bold', color: '#A696FF' }}> Add Medical Tracking</p>

            <p className="label_style" style={{ marginTop: 10, color: 'black' }}>{trackingType} </p>
            <>

                <div className='first-div' style={{ marginTop: 5, height: 200, overflowY: 'scroll' }}>

                    <div style={{ marginTop: 5 }} className="medicineview">
                        <table id='prescriptiondata' style={{ width: '100%', paddingRight: 5, borderRadius: 5, marginLeft: 5, marginRight: 5 }} >

                            <tr>
                                <th style={{ width: '50%', textAlign: 'centre' }} >Reading</th>
                                <th style={{ width: '50%', textAlign: 'centre' }}>Date and Time</th>
                            </tr>
                            {tableData.map((val) => {
                                return (
                                    < >
                                        <tr>
                                            <td className="value" style={{ textAlign: 'centre' }}>{val.value}</td>
                                            <td className="value" style={{ textAlign: 'centre' }}>{moment(val.tracking_datetime).format('DD.MM.YYYY  HH:mm')}</td>
                                        </tr>
                                    </>
                                );
                            })}


                            {
                                msg ?
                                    <p className="label_style" style={{ textAlign: 'centre', color: 'red', marginLeft: 26 }}>No data is added</p>
                                    : null
                            }


                        </table>

                    </div>
                </div>
                {
                    tableData && tableData.length > 0 && (
                        <div className='first-div' >

                            <LineChart
                                width={310}
                                height={300}
                                data={tableData}
                                margin={{
                                    top: 5,
                                    left: -30,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="create_date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="Reading"
                                    stroke="#8884d8"
                                    activeDot={{ r: 8 }}
                                />

                            </LineChart>

                        </div>
                    )
                }
                <div className='first-div' style={{ marginTop: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>


                        <p className="label_style" style={{ textAlign: 'left' }}>Add data for  </p>
                        <p className="label_style" style={{ color: 'black' }}>{trackingType}  </p>
                    </div>

                    <div style={{ marginTop: 15, marginLeft: 15 }} className="flex-div">

                        <div style={{ width: '45%', textAlign: 'left' }}>

                            <p className="label_style" > Reading Value:</p>
                        </div>
                        <div style={{ width: '35%', marginLeft: 5 }}>

                            <input
                                className="input_style"
                                // style={{ width: '50%' }}
                                type="tel"
                                name="name"
                                required
                                value={readingValue}
                                maxLength={3}
                                onChange={(e) => onReadingValueUpdate(e)}

                            />
                        </div>

                    </div>
                    <div style={{ marginTop: 20, marginLeft: 15 }} className="flex-div">
                        <div style={{ width: '45%', textAlign: 'left' }}>

                            <p className="label_style" >Date:</p>
                        </div>
                        <div style={{ width: '35%', marginLeft: 5 }}>

                            <input
                                className="input_style"
                                type="date"
                                name="date"
                                value={date}
                                onChange={(event) => handleChangeDate(event)}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: 20, marginLeft: 15 }} className="flex-div">
                        <div style={{ width: '45%', textAlign: 'left' }}>

                            <p className="label_style" >Time:</p>
                        </div>
                        <div style={{ width: '35%', marginLeft: 5 }}>

                            <input
                                className="input_style"
                                type="time"
                                name="time"
                                value={time}
                                onChange={(event) => handleChangeTime(event)}
                            />
                        </div>
                    </div>


                    <form method="post"
                        onSubmit={(event) => updateHandler(event)}
                    >
                        {
                            buttonHide == false ?
                                <button type="submit" style={{ marginTop: 30, width: 120 }} className="btn btn-auto btn-primary">
                                    Add
                                </button>
                                :
                                <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold', marginTop: 15 }}>Updating...</p>

                        }


                    </form>

                </div>
            </>
        </div>
    )
}

export default Editvitals
