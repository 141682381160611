import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import Axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import newdoctorlogo from '../assets/images/newdoctorlogo.jpg';
import $ from "jquery";
import { decode as base64_decode, encode as base64_encode } from 'base-64';

export default () => {


    const currentdate = new Date().toLocaleDateString('en-GB');

    const [docDetails, setDocDetails] = useState({});
    const [consultDetails, setConsultDetails] = useState({});
    const [clinicDetails, setClinicDetails] = useState({});
    const [docId, setDocId] = useState(Number);
    const [isPrescriptionContainerHide, setIsPrescriptionContainerHide] = useState(true);
    const [registeredPhone, setRegisteredPhone] = useState("");
    const [phoneNo, setPhoneNo] = useState("");

    const [sendingOTP, setSendingOTP] = useState(false);
    const [buttonHide, setButtonHide] = useState(false);
    const [verifyButtonHide, setVerifyButtonHide] = useState(false);
    const [OTPinput, setOTPInput] = useState("");
    const [OTPbackendResponse, setOTPBackendResponse] = useState("");

    const [isEmptyPhoneNo, setIsEmptyPhoneNo] = useState(false);
    const [OTPsectionHide, setOTPSectionHide] = useState(false);
    const [OTPVerificationsectionHide, setOTPVerificationSectionHide] = useState(false);
    const [msg, setMsg] = useState("");
    const [yourOTP, setYourOTP] = useState("");


    const navigate = useNavigate();

    let paramsid;
    const searchid = useLocation().search;
    console.log(searchid)
    paramsid = new URLSearchParams(searchid).get('text');
    console.log("consultation id ", paramsid);

    let decodedstring = base64_decode(paramsid);
    console.log("decodedstring ", decodedstring);

    const phoneNoChangeHandler = (e) => {
        setPhoneNo(e.target.value);

    }

    const OTPChangeHandler = (e) => {
        setOTPInput(e.target.value);
    }


    useEffect(() => {


        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientPrescription/get_doctor_details?id=${decodedstring}`).then((doctordetails) => {
            console.log("Doctor details ", doctordetails.data);
            setDocDetails(doctordetails.data);
            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientPrescription/get_clinic_details?doctorsid=${doctordetails.data.users_login_id}`).then((clinicdetails) => {
                console.log(" cinic details ", clinicdetails.data);
                setClinicDetails(clinicdetails.data);
            });
        });
        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientPrescription/get_consultation_details?id=${decodedstring}`).then((consultationdetails) => {
            console.log("patient consultation details ", consultationdetails.data);
            setConsultDetails(consultationdetails.data);
            // window.print();

        });
        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientPrescription/get_phonenumber_by_consultation_id?consultation_id=${decodedstring}`).then((response) => {
            console.log(" phone number ", response.data);
            setRegisteredPhone(response.data.phone_number);

        });

    }, [])

    const sendOTPHandler = () => {
        if (phoneNo != "") {
            setSendingOTP(true);
            setButtonHide(true);
            setIsEmptyPhoneNo(false);
            setMsg("")


            if (registeredPhone == phoneNo) {
                Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/Misc/sendotp?phone=${registeredPhone}`)
                    .then(OTPresponse => {
                        setYourOTP(OTPresponse.data);
                        console.log('sendOTPHandler:otp response \n', OTPresponse.data);
                        setSendingOTP(false);
                        setButtonHide(false);
                        if (OTPresponse.data) {
                            setOTPBackendResponse(OTPresponse.data);
                            setOTPSectionHide(true);
                        }

                    }).catch((err) => {
                        console.log('Reset Password: otp send failed', err);
                        setSendingOTP(false);
                        setButtonHide(false);

                    })

            } else {
                setSendingOTP(false);

                setButtonHide(false);
                setMsg("This no is not valid for this prescription.")
            }





        } else {
            setIsEmptyPhoneNo(true);
            setButtonHide(false);

        }
    }


    const verifyOTPHandler = () => {
        setVerifyButtonHide(true);
        setSendingOTP(true);
        if (OTPbackendResponse == OTPinput) {
            console.log(phoneNo)
            setIsPrescriptionContainerHide(false);
            setVerifyButtonHide(true);
            setSendingOTP(false);
            setOTPVerificationSectionHide(true);




        } else {
            setMsg("OTP is not matching");
            setVerifyButtonHide(false);
            setSendingOTP(false);
        }
    }






    return (
        <div>
            <>

                {
                    isPrescriptionContainerHide == true ?
                        <>


                            <div>
                                <input onChange={(event) => phoneNoChangeHandler(event)}
                                    style={{ border: '1px solid rgb(185, 184, 184)', backgroundColor: '#DDDDDD', color: 'black', width: '40%', height: 40, paddingLeft: 8, fontSize: 12, borderRadius: 8, marginTop: 50, fontSize: 'small', width: '60%', textAlign: 'center', }} type="number" placeholder="Enter phone number" />
                            </div>

                            <div style={{ marginTop: 5 }}>

                                {
                                    isEmptyPhoneNo ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}>Please enter phone no</p>
                                        :
                                        null
                                }
                                {msg ? <p style={{ fontSize: 16, fontWeight: 'bold', color: 'red', marginTop: 10 }}>{msg}</p> : null}


                                {
                                    buttonHide == false ?
                                        <button className="otp_button" onClick={sendOTPHandler} >SEND OTP</button>
                                        :
                                        null
                                }





                                {
                                    sendingOTP ?
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: 'green' }}>Sending...</p>
                                        :
                                        null
                                }

                            </div>


                            {
                                OTPsectionHide == true && OTPVerificationsectionHide == false ?
                                    <>

                                        {
                                            yourOTP ?
                                                <p style={{ fontSize: 16, fontWeight: 'bold', color: 'green', marginTop: 20 }}>Your OTP IS : {yourOTP}</p>
                                                : null

                                        }
                                        <div>
                                            <input onChange={(event) => OTPChangeHandler(event)}
                                                style={{ border: '1px solid rgb(185, 184, 184)', backgroundColor: '#DDDDDD', color: 'black', width: '40%', height: 30, paddingLeft: 8, fontSize: 12, borderRadius: 8, marginTop: 50, fontSize: 'small' }} type="number" placeholder="Enter your 6 digit OTP" />
                                        </div>
                                        {
                                            verifyButtonHide == false ?
                                                <button className="otp_button" onClick={verifyOTPHandler} >VERIFY OTP</button>
                                                :
                                                null
                                        }


                                        {
                                            sendingOTP ?
                                                <p style={{ fontSize: 16, fontWeight: 'bold', color: 'green' }}>Verifing...</p>
                                                :
                                                null
                                        }
                                        {<p style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}>{msg}</p>}

                                    </>
                                    : null
                            }




                        </>
                        :


                        <div
                            style={{ marginTop: 15 }}
                            id="content" className="prescription-container">

                            <p className="header-bar">e-Prescription</p>



                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card-innr card-innr-fix">
                                        <p style={{ textAlign: 'left' }} className="label">
                                            {docDetails.doctors_name}
                                        </p>
                                        <div className="flex-div">
                                            <p className="label" style={{ fontSize: 13, textAlign: 'left', marginTop: -10 }}>Reg No:</p>
                                            <p className="value" style={{ marginTop: -10, fontSize: 13, }}>
                                                &nbsp;{docDetails.licence_number}
                                            </p>
                                        </div>

                                        <p className="value" style={{ fontSize: 13, marginTop: -10, textAlign: 'left' }}  >
                                            {docDetails.degree}
                                        </p>
                                        <div className="flex-div">
                                            <p style={{ textAlign: 'left', fontSize: 13, marginTop: -10 }} className="label">Specialist:</p>
                                            <p className="value" style={{ fontSize: 13, marginTop: -10, textAlign: 'left' }}  >{docDetails.specialization}</p>



                                        </div>

                                    </div>


                                </div>
                                <div className="col-md-3">

                                    <div className="card-innr card-innr-fix">
                                        <img style={{ height: 'auto', width: 'auto', marginTop: -10 }} src={newdoctorlogo} />
                                    </div>
                                </div>
                                <div className="col-md-5">

                                    <div className="card-innr card-innr-fix">
                                        <p style={{ textAlign: 'left', fontSize: 18 }} className="label">{clinicDetails.name}</p>
                                        <p className="value" style={{ fontSize: 13, marginTop: -10, textAlign: 'left' }}  >{clinicDetails.address}</p>
                                        <div className="flex-div">
                                            <p className="label" style={{ fontSize: 13, textAlign: 'left', marginTop: -10 }}>Mobile:</p>
                                            <p className="value" style={{ marginTop: -10, fontSize: 13 }}>&nbsp;{clinicDetails.phonenumber_1}/{clinicDetails.phonenumber_2}</p>
                                        </div>
                                        <p className="value" style={{ fontSize: 13, marginTop: -10, textAlign: 'left' }}  >Everyday – 9 to 11:30am Except Sunday</p>


                                    </div>
                                </div>

                            </div>


                            <div className="row" style={{ marginLeft: 15 }}>
                                <div className="col-md-4 " >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <p className="label " style={{ fontSize: 13 }}>Name:&nbsp;  </p>
                                        <p className="value" style={{ fontSize: 13 }}>{consultDetails.first_name} {consultDetails.last_name}&nbsp;(&nbsp;{consultDetails.gender} {consultDetails.age} ) </p>

                                    </div>

                                </div>
                                <div className="col-md-2 " >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p className="label" style={{ fontSize: 13 }}>Height:&nbsp;   </p>
                                        {consultDetails.height != "null" ?
                                            <p className="value" style={{ fontSize: 13 }}> {consultDetails.height}  </p>
                                            : null

                                        }

                                    </div>

                                </div>
                                <div className="col-md-2 " >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p className="label" style={{ fontSize: 13 }}>Weight:&nbsp;   </p>
                                        {consultDetails.weight != "null" ?
                                            <p className="value" style={{ fontSize: 13 }}> {consultDetails.weight}  </p>
                                            : null

                                        }
                                    </div>

                                </div>
                                <div className="col-md-2 " >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <p className="label" style={{ fontSize: 13 }}>Pulse Rate:&nbsp;  </p>
                                        {consultDetails.pulse_rate != "null" ?
                                            <p className="value" style={{ fontSize: 13 }}> {consultDetails.pulse_rate}  </p>
                                            : null

                                        }
                                    </div>

                                </div>

                            </div>

                            <div className="row" style={{ marginLeft: 15 }}>
                                <div className="col-md-6 ">


                                </div>
                                <div className="col-md-2 ">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p className="label" style={{ fontSize: 13 }}>BP:&nbsp;   </p>
                                        {consultDetails.blood_pressure != "null" ?
                                            <p className="value" style={{ fontSize: 13 }}> {consultDetails.blood_pressure}  </p>
                                            : null

                                        }
                                    </div>

                                </div>
                                <div className="col-md-3 ">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <p className="label" style={{ fontSize: 13 }}>Random Sugar:&nbsp;   </p>
                                        {consultDetails.blood_sugar_random != "null" ?
                                            <p className="value" style={{ fontSize: 13 }}> {consultDetails.blood_sugar_random}  </p>
                                            : null

                                        }
                                    </div>

                                </div>


                            </div>



                            <div style={{ marginLeft: 30 }}>


                                <div className="row">
                                    <div className="col-md-2">
                                        <p style={{ textAlign: 'left', fontSize: 13 }} className="label">Complaints:</p>

                                    </div>
                                    <div className="col-md-10">
                                        <p style={{ textAlign: 'left', marginLeft: -10 }} className="value">{consultDetails.chief_complaints}</p>

                                    </div>

                                </div>
                                <div style={{ marginTop: 10 }} className="row">
                                    <div className="col-md-2">
                                        <p style={{ textAlign: 'left', fontSize: 13 }} className="label">Findings:</p>

                                    </div>
                                    <div className="col-md-10">
                                        <p style={{ textAlign: 'left', marginLeft: -10 }} className="value">{consultDetails.doctor_findings}</p>

                                    </div>

                                </div>

                                <div className="left-display-container" style={{ marginTop: 10 }}>
                                    <div className="medicineview">
                                        <table id='medicinedata' style={{ width: '100%', paddingRight: 50, borderRadius: 5, marginRight: 30 }} >
                                            <tr>
                                                <th style={{ width: '28%', fontSize: 13, textAlign: 'left', paddingLeft: 15 }}>Medicine</th>
                                                <th style={{ width: '18%', fontSize: 13, paddingLeft: 50, textAlign: 'left' }}>Dosage</th>
                                                <th style={{ width: '30%', fontSize: 13, textAlign: 'left' }}>Duration</th>

                                            </tr>
                                        </table>
                                    </div>
                                    <textarea style={{ overflow: 'hidden', width: '96%', marginTop: 15, fontSize: 14, fontWeight: 500, color: 'black' }} className="input-bordered input-textarea"
                                        value={consultDetails.doctor_advice}

                                    ></textarea>
                                </div>
                                <div className="left-display-container" style={{ marginTop: 10 }}>
                                    <p className="label" style={{ fontSize: 13 }}>Diagonstic/Lab:  </p>
                                    <textarea style={{ overflow: 'hidden', width: '96%', fontSize: 14, fontWeight: 500, color: 'black' }} className="input-bordered input-textarea"
                                        value={consultDetails.doctor_investigation}

                                    ></textarea>
                                </div>
                                <div className="left-display-container" style={{ marginTop: 10 }}>
                                    <p className="label" style={{ fontSize: 13 }}>Advice:  </p>
                                    <textarea style={{ overflow: 'hidden', width: '96%' }} className="input-bordered input-textarea"

                                    ></textarea>
                                </div>


                                <div className="left-display-container" style={{ marginTop: 10 }}>
                                    <div className="flex-div">
                                        <div style={{ width: '20%' }}>
                                            <p style={{ fontSize: 13 }} className="label"> Advice: </p>

                                        </div>
                                        <div style={{ width: '32%' }}>

                                            <p style={{ color: 'green', fontSize: 13 }} className="label"> Do's: </p>
                                        </div>
                                        <div style={{ width: '32%' }}>

                                            <p style={{ color: 'red', fontSize: 13 }} className="label"> Don'ts: </p>
                                        </div>


                                    </div>


                                    <div className="flex-div">
                                        <div style={{ width: '45%' }}>
                                            <textarea
                                                value={consultDetails.advice_does}
                                                style={{ height: 120, fontSize: 14, fontWeight: '500', color: 'black', borderColor: '#90EE90' }} className="input-bordered input-textarea"

                                            />
                                        </div>
                                        <div style={{ width: '6%' }}>
                                        </div>
                                        <div style={{ width: '45%' }}>
                                            <textarea
                                                value={consultDetails.advice_donts}
                                                style={{ height: 120, fontSize: 14, fontWeight: '500', color: 'black', borderColor: '#FE7275' }} className="input-bordered input-textarea"

                                            />
                                        </div>
                                    </div>
                                </div>






                                <div style={{ marginTop: 10, justifyContent: 'space-between', display: 'flex', flexDirection: 'row', paddingRight: 30 }}>
                                    <p style={{ marginTop: 15, fontSize: 13 }} className="label">Signature of Doctor:</p>
                                    <p style={{ marginTop: 15, fontSize: 13 }} className="value">Date: {currentdate}</p>
                                </div>
                            </div>

                        </div>
                }

            </>


        </div>
    )
}