import { atom, selector } from 'recoil';

export const is_patient_profile_data_updated = atom({
    key: 'is_patient_profile_data_updated',
    default: {},
});

export const patient_profile_image = atom({
    key: 'patient_profile_image',
    default: [],
});

export const patient_profile_image_show = atom({
    key: 'patient_profile_image_show',
    default: [],
});
export const patient_medical_tracking_details = atom({
    key: 'patient_medical_tracking_details',
    default: [],
});
export const patient_medical_data_loading = atom({
    key: 'patient_medical_data_loading',
    default: [],
});

export const is_patient_custom_medical_data_updated = atom({
    key: 'is_patient_custom_medical_data_updated',
    default: [],
});