import React from 'react'
import loading from '../assets/images/loading.gif';

const Spinner = () => {
    return (
        <div>
            <div className="text-center">
                <img src={loading} style={{ height: 35, width: 35, marginTop: '60%' }} />
            </div>

        </div>
    )
}

export default Spinner
