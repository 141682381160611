import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Axios from 'axios';
import documents from '../assets/images/documents.png';
import { useNavigate } from "react-router-dom";
import back from '../assets/images/back.png';
import '../App.css';
import newdoctorlogo from '../assets/images/newdoctorlogo.jpg';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

export default () => {
    const [consultDetails, setConsultDetails] = useState([]);
    const [uploadedDoc, setUploadedDoc] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [medicineTableData, setMedicineTableData] = useState([]);
    const [docDetails, setDocDetails] = useState({});
    const [clinicDetails, setClinicDetails] = useState({});
    const [clinicImg, setClinicImg] = useState("");
    const navigate = useNavigate();
    const location = useLocation();


    let paramsid;
    const searchid = useLocation().search;
    // paramsid = new URLSearchParams(searchid).get('constid');
    // console.log(paramsid);



    console.log(searchid)
    paramsid = new URLSearchParams(searchid).get('text');
    console.log("consultation id ", paramsid);

    let decodedstring = base64_decode(paramsid);
    console.log("decodedstring ", decodedstring);
    let domain = window.location.host;
    console.log(`${domain}/ConsultationHistoryDetails/${searchid}`)
    let id = localStorage.getItem("patientid");
    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientPrescription/get_doctor_details?id=${decodedstring}`).then((doctordetails) => {
            console.log("Doctor details ", doctordetails.data);
            if (doctordetails.data.status == '1') {
                setDocDetails(doctordetails.data.response);
            }

            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientPrescription/get_clinic_details?consultationid=${decodedstring}&patientsid=${id}`).then((clinicdetails) => {
                console.log(" cinic details ", clinicdetails.data);
                if (clinicdetails.data.status == '1') {
                    setClinicDetails(clinicdetails.data.response);
                    Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/File_reader/read_file_content_clinic?clinicid=${clinicdetails.data.response.id}&file=${clinicdetails.data.response.logo}`).then((response) => {
                        console.log("buffer file = ", response.data.response);
                        setClinicImg(response.data.base64String);

                    });
                }
            });
        });
        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientPrescription/get_consultation_details?id=${decodedstring}`).then((response) => {
            console.log("consultation details ", response.data);
            if (response.data.status == '1') {
                setConsultDetails(response.data.response);
                setMedicineTableData(JSON.parse(response.data.response.medicine));
            }
        });
        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientsReports/get_uploaded_documents?consultation_id_fk=${decodedstring}`).then((response) => {
            console.log("uploaded doc details ", response.data);
            if (response.data.status == '1') {
                setUploadedDoc(response.data.response);
            }
        });
    }, [])


    const openNewTabHandler = (file) => {
        const url = `${file}`;
        window.open(url, "toolbar=yes,scrollbars=yes,resizable=yes,width=400,height=400");
    }

    const phoneNumberChangeHandler = (e) => {
        setPhoneNumber(e.target.value);

    }

    const shareWithDoctorsHandler = () => {
        console.log("entered");
    }


    return (
        <div>
            {/* <div style={{ textAlign: 'left' }}>
                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/ConsultationHistoryList")} />
            </div> */}
            <div style={{ marginTop: 20, width: 550 }}>
                <button
                    onClick={() => {
                        navigate("/MyDoctors", { state: paramsid })

                    }}
                    className="view-details">Share Details </button>
            </div>

            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', marginLeft: 20, marginRight: 20 }}>

                <input style={{ border: '1px solid rgb(185, 184, 184)', width: '40%', height: 30, paddingLeft: 8, fontSize: 12, borderRadius: 7, textAlign: 'left' }} type="number" placeholder="Phone Number "
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(event) => phoneNumberChangeHandler(event)}
                />
                <div style={{ marginLeft: 10 }}>
                    <div className="view-details"  >
                        <div style={{ marginTop: 5 }}>
                            <a style={{ textDecoration: 'none', color: 'white' }} href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${domain}/ConsultationHistoryDetails?id=${paramsid}`}>Share with doctors </a>
                        </div>
                    </div>
                </div>


            </div>


            <p style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>Consultation Details (&nbsp;{consultDetails.name} &nbsp;) </p>
            <div
                style={{ marginTop: 15 }}
                id="content" className="prescription-container">

                <p className="header-bar">e-Prescription</p>



                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '33%' }}>
                        <div className="card-innr card-innr-fix">
                            <p style={{ textAlign: 'left' }} className="label">{docDetails.doctors_name}</p>
                            <div className="flex-div">
                                <p className="label" style={{ fontSize: 13, textAlign: 'left', marginTop: 5 }}>Reg No:</p>
                                <p className="value" style={{ marginTop: 5, fontSize: 13, }}>&nbsp;{docDetails.licence_number}</p>
                            </div>

                            <p className="value" style={{ fontSize: 13, marginTop: 5, textAlign: 'left' }}  >{docDetails.degree}</p>
                            <div className="flex-div">
                                <p style={{ textAlign: 'left', fontSize: 13, marginTop: 5 }} className="label">Specialist:</p>
                                <p className="value" style={{ fontSize: 13, marginTop: 5, textAlign: 'left' }}  >{docDetails.specialization}</p>



                            </div>

                        </div>


                    </div>
                    <div style={{ width: '33%' }}>

                        <div className="card-innr card-innr-fix">
                            <img style={{ height: 196, width: 196, marginTop: -10 }} src={clinicImg} />
                        </div>
                    </div>
                    <div style={{ width: '33%' }}>

                        <div className="card-innr card-innr-fix">
                            <p style={{ textAlign: 'left', fontSize: 18 }} className="label">{clinicDetails.name}</p>
                            <p className="value" style={{ fontSize: 13, marginTop: 5, textAlign: 'left' }}  >{clinicDetails.address}</p>
                            <div className="flex-div">
                                <p className="label" style={{ fontSize: 13, textAlign: 'left', marginTop: 5 }}>Mobile:</p>
                                <p className="value" style={{ marginTop: 5, fontSize: 13 }}>&nbsp;{clinicDetails.phonenumber_1}/{clinicDetails.phonenumber_2}</p>
                            </div>
                            <p className="value" style={{ fontSize: 13, marginTop: 5, textAlign: 'left' }}  >Everyday – 9 to 11:30am Except Sunday</p>


                        </div>
                    </div>

                </div>


                <div className="row" style={{ marginLeft: 15 }}>
                    <div className="col-md-4 " >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <p className="label " style={{ fontSize: 13 }}>Name:&nbsp;  </p>
                            <p className="value" style={{ fontSize: 13 }}>{consultDetails.name}&nbsp;(&nbsp;{consultDetails.gender} {consultDetails.age} ) </p>

                        </div>

                    </div>


                </div>

                <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <div style={{ width: '20%' }} >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <p className="label" style={{ fontSize: 13 }}>Height(ft):&nbsp;   </p>
                            {consultDetails.height != "null" ?
                                <p className="value" style={{ fontSize: 13 }}> {consultDetails.height}  </p>
                                : null

                            }

                        </div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <p className="label" style={{ fontSize: 13 }}>Weight(kg):&nbsp;   </p>
                            {consultDetails.weight != "null" ?
                                <p className="value" style={{ fontSize: 13 }}> {consultDetails.weight}  </p>
                                : null

                            }
                        </div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <p className="label" style={{ fontSize: 13 }}>Pulse Rate(bpm):&nbsp;  </p>
                            {consultDetails.pulse_rate != "null" ?
                                <p className="value" style={{ fontSize: 13 }}> {consultDetails.pulse_rate}  </p>
                                : null

                            }
                        </div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <p className="label" style={{ fontSize: 13 }}>Bp(mm/Hg):&nbsp;   </p>
                            {consultDetails.blood_pressure_mm != "0" ?
                                <p className="value" style={{ fontSize: 13 }}> {consultDetails.blood_pressure_mm}/{consultDetails.blood_pressure_hg}  </p>
                                : null

                            }
                        </div>
                    </div>

                    <div style={{ width: '20%' }} >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <p className="label" style={{ fontSize: 13 }}>Random Sugar:&nbsp;   </p>
                            {consultDetails.blood_sugar_random != "null" ?
                                <p className="value" style={{ fontSize: 13 }}> {consultDetails.blood_sugar_random}  </p>
                                : null

                            }
                        </div>
                    </div>

                </div>


                <div style={{ marginLeft: 30, marginTop: 10 }}>


                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '20%' }}>
                            <p style={{ textAlign: 'left', fontSize: 13 }} className="label">Complaints:</p>

                        </div>
                        <div style={{ width: '10%' }}>
                            <p style={{ textAlign: 'left', }} className="value">{consultDetails.chief_complaints}</p>

                        </div>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div style={{ width: '20%' }}>
                            <p style={{ textAlign: 'left', fontSize: 13 }} className="label">Findings:</p>

                        </div>

                        <div style={{ width: '10%' }}>
                            <p style={{ textAlign: 'left', }} className="value">{consultDetails.doctor_findings}</p>

                        </div>

                    </div>

                    <div className="left-display-container" style={{ marginTop: 10 }}>
                        <p className="label" style={{ fontSize: 13 }}>Notes:  </p>

                        <textarea style={{ overflow: 'hidden', width: '96%', fontSize: 14, fontWeight: 500, color: 'black' }} className="input-bordered input-textarea"
                            value={consultDetails.notes}

                        >


                        </textarea>
                    </div>

                    <div className="left-display-container" style={{ marginTop: 10, marginRight: 35 }}>


                        <table id="prescriptiondata" style={{ marginTop: 20 }} >
                            <thead  >
                                <tr >
                                    <th style={{ textAlign: 'center', padding: 10 }}>Medicine Name</th>
                                    <th style={{ textAlign: 'center', padding: 10 }}>Dose</th>
                                    <th style={{ textAlign: 'center', padding: 10 }}>When</th>
                                    <th style={{ textAlign: 'center', padding: 10 }}>Frequency</th>
                                    <th style={{ textAlign: 'center', padding: 10 }}>Duration</th>

                                </tr>
                            </thead>
                            <tbody>

                                {medicineTableData.map((details, key) => {
                                    return (
                                        <>
                                            <tr>
                                                <td style={{ fontSize: 15, fontWeight: '600', textAlign: 'left', padding: 10 }}  >
                                                    {details.name}
                                                </td>
                                                <td style={{ fontSize: 15, fontWeight: '600', textAlign: 'left', padding: 10 }} >
                                                    {details.dose}
                                                </td>
                                                <td style={{ fontSize: 15, fontWeight: '600', textAlign: 'left', padding: 10 }} >
                                                    {details.when}
                                                </td>
                                                <td style={{ fontSize: 15, fontWeight: '600', textAlign: 'left', padding: 10 }} >
                                                    {details.frequency}
                                                </td>
                                                <td style={{ fontSize: 15, fontWeight: '600', textAlign: 'left', padding: 10 }} >
                                                    {details.duration}
                                                </td>

                                            </tr>
                                        </>
                                    )
                                })}

                            </tbody>
                        </table>


                    </div>
                    <div className="left-display-container" style={{ marginTop: 10 }}>
                        <p className="label" style={{ fontSize: 13 }}>Diagonstic/Lab Tests Prescribed:  </p>
                        <textarea style={{ overflow: 'hidden', width: '96%', fontSize: 14, fontWeight: 500, color: 'black' }} className="input-bordered input-textarea"
                            value={consultDetails.doctor_investigation}

                        ></textarea>
                    </div>

                    {/* <div className="left-display-container" style={{ marginTop: 10 }}>
                        <p className="label" style={{ fontSize: 13 }}>Advice:  </p>
                        <textarea style={{ overflow: 'hidden', width: '96%' }} className="input-bordered input-textarea"

                        ></textarea>
                    </div> */}


                    <div className="left-display-container" style={{ marginTop: 10 }}>
                        <div className="flex-div">
                            <div style={{ width: '20%' }}>
                                <p style={{ fontSize: 13 }} className="label"> Advice: </p>

                            </div>
                            <div style={{ width: '32%' }}>

                                <p style={{ color: 'green', fontSize: 13 }} className="label"> Do's: </p>
                            </div>
                            <div style={{ width: '32%' }}>

                                <p style={{ color: 'red', fontSize: 13 }} className="label"> Don'ts: </p>
                            </div>


                        </div>


                        <div className="flex-div">
                            <div style={{ width: '45%' }}>
                                <textarea value={consultDetails.advice_does} style={{ height: 120, fontSize: 14, fontWeight: '500', color: 'black', borderColor: '#90EE90' }} className="input-bordered input-textarea"

                                />
                            </div>
                            <div style={{ width: '6%' }}>
                            </div>
                            <div style={{ width: '45%' }}>
                                <textarea value={consultDetails.advice_donts} style={{ height: 120, fontSize: 14, fontWeight: '500', color: 'black', borderColor: '#FE7275' }} className="input-bordered input-textarea"

                                />
                            </div>
                        </div>
                    </div>


                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', paddingRight: 30 }}>
                        <p style={{ marginTop: 15, fontSize: 13 }} className="label">Next Visit:</p>
                        <p style={{ marginTop: 15, fontSize: 13 }} className="value">&nbsp;{consultDetails.next_visit}</p>
                    </div>





                    {/* <div style={{ marginTop: 10, justifyContent: 'space-between', display: 'flex', flexDirection: 'row', paddingRight: 30 }}>
                        <p style={{ marginTop: 15, fontSize: 13 }} className="label">Signature of Doctor:</p>
                        <p style={{ marginTop: 15, fontSize: 13 }} className="value">Date: {currentdate}</p>
                    </div> */}
                </div>

            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 20 }}>

                <label style={{ fontSize: 15, fontWeight: 'bold', color: 'rgb(34, 189, 250)', }}> Visiting Purpose : - </label><br />
                <p style={{fontSize:14,color:'black',fontWeight:'normal',marginTop:2}}>&nbsp;{constDetails.visiting_purpose}</p>
            </div> */}

            {/* <div style={{ marginTop: 8, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                <p style={{ fontSize: 12, fontWeight: 'bold', color: 'black' }}>Doctors Name - {constDetails.doctors_name}</p>

            </div> */}

            {/* <p style={{ fontSize: 16, fontWeight: 'bold' }}> {constDetails.firstname} {constDetails.lastname}</p> */}

            {/* <div className="main-div">
                <div style={{ width: '50%', height: 'auto', backgroundColor: '#FFFAFA' }}>
                    <div className="patient-consultation-summary">
                        <div style={{ display: 'flex' }}>
                            <div style={{ textAlign: 'left' }}>
                                <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 8 }}>Gender</p>
                                <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 8 }}>Height</p>
                                <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 8 }}>Weight</p>
                                <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 8 }}>Body Temperature</p>


                            </div>
                            <div style={{ textAlign: 'right', marginRight: 10 }}>
                                <p style={{ fontSize: 12, marginTop: 8 }}>{constDetails.gender}</p>
                                <p style={{ fontSize: 12, marginTop: 8 }}>{constDetails.height}</p>
                                <p style={{ fontSize: 12, marginTop: 8 }}>{constDetails.weight}</p>
                                <p style={{ fontSize: 12, marginTop: 8 }}>{constDetails.temperature}</p>


                            </div>
                        </div>
                    </div>
                </div>


                <div style={{ width: '50%', height: 'auto', backgroundColor: '#FFFAFA' }}>
                    <div className="patient-consultation-summary">
                        <div style={{ display: 'flex' }}>
                            <div style={{ textAlign: 'left', marginLeft: 10 }}>

                                <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 8 }}>Date of Birth</p>
                                <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 8 }}>Blood Pressure</p>
                                <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 8 }}>PulseRate</p>
                                <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 8 }}>Random Sugar</p>

                            </div>
                            <div style={{ textAlign: 'right', marginRight: 10 }}>

                                <p style={{ fontSize: 12, marginTop: 8 }}>{constDetails.dob}</p>

                                <p style={{ fontSize: 12, marginTop: 8 }}>{constDetails.blood_pressure}</p>
                                <p style={{ fontSize: 12, marginTop: 8 }}>{constDetails.pulse_rate}</p>
                                <p style={{ fontSize: 12, marginTop: 8 }}>{constDetails.blood_sugar_random}</p>

                            </div>
                        </div>
                    </div>
                </div>

            </div> */}
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 20, marginTop: -10 }}>

                <label style={{ fontSize: 15, fontWeight: 'bold', color: 'rgb(34, 189, 250)', }}> Doctors Name : - </label><br />
                <p style={{ fontSize: 14, color: 'black', fontWeight: 'normal', marginTop: 2 }}>&nbsp;{constDetails.doctors_name}</p>
            </div> */}

            {/* <div className="main-div">

                <div style={{ height: 'auto', backgroundColor: '#FFFAFA' }}>
                    <div className="doctor-reports">
                        <div style={{ textAlign: 'left' }} >
                            <label style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(34, 189, 250)', }}>  Complaints : - </label><br />
                            <p style={{ marginTop: 5, fontSize: 13 }}>{constDetails.chief_complaints}</p>
                        </div>
                        <div style={{ textAlign: 'left', marginTop: 10 }} >
                            <label style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(34, 189, 250)' }}>Findings : -</label><br />
                            <p style={{ marginTop: 5, fontSize: 13 }}>{constDetails.doctor_findings}</p>
                        </div>
                        <div style={{ textAlign: 'left', marginTop: 10 }} >
                            <label style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(34, 189, 250)' }}>Diagnostic/Lab : -</label><br />
                            <p style={{ marginTop: 5, fontSize: 13 }}>{constDetails.doctor_investigation}</p>
                        </div>
                        <div style={{ textAlign: 'left', marginTop: 10 }} >
                            <label style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(34, 189, 250)' }}>Medicine : -</label><br />
                            <p style={{ marginTop: 5, fontSize: 13 }}>{constDetails.doctor_advice}</p>
                        </div>
                        <div style={{ textAlign: 'left', marginTop: 10 }} >
                            <label style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(34, 189, 250)' }}>Advice (&nbsp;Does&nbsp;) : -</label><br />
                            <p style={{ marginTop: 5, fontSize: 13 }}>{constDetails.advice_does}</p>
                        </div>
                        <div style={{ textAlign: 'left', marginTop: 10 }} >
                            <label style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(34, 189, 250)' }}>Advice (&nbsp;Dont's&nbsp;) : -</label><br />
                            <p style={{ marginTop: 5, fontSize: 13 }}>{constDetails.advice_donts}</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div >
                <div style={{ textAlign: 'left', marginLeft: 20, }}>

                    <label style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(34, 189, 250)' }}> Lab Reports : - </label><br />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: 'auto', paddingBottom: 20, }}>
                    {
                        uploadedDoc.map((details) => {
                            return (
                                <div key={details.file} style={{ textAlign: 'left', paddingTop: 10 }}
                                    onClick={() => { openNewTabHandler(details.file) }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginLeft: 20 }}>
                                        <div style={{ width: '10%' }}>
                                            <img style={{ height: 25, width: 23, paddingTop: 5, backgroundColor: '#FFCC66', borderRadius: 20 }} src={documents} />
                                        </div>
                                        <div style={{ width: '90%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: -8 }}>
                                                <p style={{ fontSize: 13, fontWeight: 'bold', marginLeft: 5 }}>{details.document_name}</p>
                                            </div>
                                            <p style={{ fontSize: 13, fontWeight: 'bold', marginLeft: 5, marginTop: -7 }}>{details.create_date_time}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div> */}


        </div>
    )
}