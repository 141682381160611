import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import back from '../assets/images/back.png'
import Spinner from '../components/Spinner'
import { Plugins } from '@capacitor/core';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page } from 'react-pdf';
export default () => {
    const [img, setImg] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    let filename;
    let patientid;
    let fileType;
    const searchid = useLocation().search;
    filename = new URLSearchParams(searchid).get('file');
    patientid = new URLSearchParams(searchid).get('patientid');
    fileType = new URLSearchParams(searchid).get('fileType');
    console.log("File Reader: Filename= ", filename);

    console.log("File Reader: File Type= ", fileType);

    const location = useLocation();
    console.log(location.state);
    filename = location.state.file;
    patientid = location.state.patientUniqueId;
    fileType = location.state.fileType;

    useEffect(() => {

        const fetchPdf = async () => {

            try {
                axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/File_reader/read_file_content_patient?patientid=${patientid}&file=${filename}`).then((response) => {
                    console.log("buffer file = ", response.data.base64String);
                    setLoading(false);
                    setImg(response.data.base64String);
                });

            } catch (error) {
                console.error('Error fetching PDF:', error);
            }
        };


        fetchPdf();

    }, []);



    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/ViewMedicalRecors", { state: { id: location.state.id } });
    }
    return (
        <>
            {
                loading ? <Spinner /> :
                    <>
                        <div style={{ textAlign: 'left' }}>

                            <img src={back} style={{ height: 20, width: 20, marginTop: 15, marginLeft: 20 }} onClick={backHandler} />
                        </div>
                        <div>

                            {

                                fileType == "application/pdf" ?
                                    <>
                                        <div>
                                            <Document file={img} > </Document>
                                        </div>

                                    </>
                                    :
                                    < TransformWrapper
                                        defaultScale={1}
                                        defaultPositionX={200}
                                        defaultPositionY={100}
                                    >
                                        <TransformComponent>
                                            <div style={{ marginRight: 10 }}>
                                                <img style={{ height: 'auto', width: 'auto', marginTop: 70, marginLeft: 7 }} src={img} />
                                            </div>
                                        </TransformComponent>
                                    </TransformWrapper>

                            }
                        </div>
                    </>}

        </>
    )
}